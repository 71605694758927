import { Collapse } from 'antd';
import { useState } from 'react';
import CommentsAmountChart from '../../components/CommentsAmountChart';
import PostsAmountChart from '../../components/PostsAmountChart';
import NewsAggregationChart from './NewsAggregationChart';
import SourceGrowthChart from './SourceGrowthChart';

const ChartsPage: React.FC = (): React.ReactElement => {
    const [value, setValue] = useState<string[]>(['1']);
    const onChange = (key: string | string[]) => {
        setValue(key as string[]);
    };

    return (
        <Collapse defaultActiveKey={value} onChange={onChange}>
            <Collapse.Panel header="Прирост новых источников" key="1">
                <SourceGrowthChart />
            </Collapse.Panel>
            <Collapse.Panel header="Количество постов по дате парсинга" key="2">
                <PostsAmountChart dateType="parsed" />
            </Collapse.Panel>
            <Collapse.Panel header="Новости по категориям" key="3">
                <NewsAggregationChart />
            </Collapse.Panel>
            <Collapse.Panel header="Количество постов по дате публикации" key="4">
                <PostsAmountChart dateType="posted" />
            </Collapse.Panel>
            <Collapse.Panel header="Количество комментариев по дате публикации" key="5">
                <CommentsAmountChart />
            </Collapse.Panel>
        </Collapse>
    );
};
export default ChartsPage;
