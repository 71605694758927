import { Dispatch } from '@reduxjs/toolkit';
import SourceService from '../../../services/Source/SourceService';
import { AppThunk } from '../../store';
import { sourceActions } from '../index';

export const getTypes = ():
AppThunk => (dispatch: Dispatch): void => {
    dispatch(sourceActions.setTypesLoadingStatus(true));
    SourceService.fetchTypes()
        .then((res) => {
            dispatch(sourceActions.setTypes(res.data));
            dispatch(sourceActions.setTypesLoadingStatus(false));
        });
};
