import { useState } from 'react';
import { IAuthor } from '../../services/Authors/types';
import AuthorEditModal from './AuthorEditModal';
import AuthorsTable from './AuthorsTable';

const AuthorsPage: React.FC = (): React.ReactElement => {
    const [editableAuthor, setEditableAuthor] = useState<IAuthor | null>(null);

    const handleClickAuthorEdit = (author: IAuthor): void => {
        setEditableAuthor(author);
    };

    const clearEditableAuthor = (): void => {
        setEditableAuthor(null);
    };

    return (
        <div>
            <AuthorsTable handleClickAuthorEdit={handleClickAuthorEdit} />

            {editableAuthor
                && <AuthorEditModal author={editableAuthor} clearEditableAuthor={clearEditableAuthor} />}
        </div>
    );
};
export default AuthorsPage;
