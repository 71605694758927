import { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    IGroupByAuthorParams,
    ISourceFilterSchema,
    ISourceSearchColumn,
    ISourceSearchSchema,
    ISourceSortSchema,
    SourceSortColumn,
} from '../../services/Source/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { sourceActions } from '../../store/source/index';
import { getGroupSourceByAuthor } from '../../store/source/thunks/get-group-source-by-author';
import { getSourcesTotalCount } from '../../store/source/thunks/get-total-count';
import SourcesPageComponent from './component';
import { ISourceFilterData } from './SourceFilter/types';
import { SortOrder } from '../../services/types';

const limit = 40;

const SourcesPage: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const sourceState = useAppSelector((state) => state.source);
    const [searchParams, setSearchParams] = useSearchParams();
    const [inactiveDate, setInactiveDate] = useState<Moment | null>(null);
    const [page, setPage] = useState<number>(() => Number(searchParams.get('page')));
    const [filterSearch, setFilterSearch] = useState<ISourceSearchSchema | null>(null);
    const [filterSort, setFilterSort] = useState<ISourceSortSchema>(null);
    const [filter, setFilter] = useState<ISourceFilterSchema | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const args = useMemo(() => {
        let obj: IGroupByAuthorParams = {};
        if (filterSearch && filterSearch.pattern.length >= 1) {
            obj = {
                search_params: filterSearch,
            };
        }

        if (filterSort) {
            obj.sort_params = filterSort;
        }

        if (filter) {
            obj.filter_params = filter;
        }

        if (inactiveDate) {
            obj.inactive_date = inactiveDate.toISOString();
        }

        return obj;
    }, [inactiveDate, filterSearch, filterSort, filter]);

    useEffect(() => {
        dispatch(sourceActions.clearGroupedSources());
        dispatch(getGroupSourceByAuthor({ ...args, page, limit }));
    }, [dispatch, page, args]);

    useEffect(() => {
        dispatch(getSourcesTotalCount(args));
    }, [dispatch, args]);

    useEffect(() => {
        const pageValue = Number(searchParams.get('page'));
        if (pageValue === 0) {
            navigate(`${location.pathname}?page=1`);
        }
        setPage(pageValue);
    }, [searchParams, navigate, location]);

    const onChangePage = (nextPage: number): void => {
        setSearchParams({ page: String(nextPage) });
    };

    const onSearch = (column: ISourceSearchColumn, value: string): void => {
        setFilterSearch(
            value.length >= 1
                ? {
                    columns: [column],
                    pattern: value,
                }
                : null,
        );
    };

    const handleClickSort = (
        column: SourceSortColumn,
        direction: SortOrder,
    ): void => {
        setFilterSort(
            direction
                ? {
                    column,
                    order: direction,
                }
                : null,
        );
    };

    const onChangeFilter = (data: ISourceFilterData): void => {
        setFilter(data.filterParams);
        setInactiveDate(data.inactiveDate);
    };

    const totalEnabledSources = useMemo(() => {
        let value = 0;
        if (!sourceState.totalCount) return 0;
        Object.values(sourceState.totalCount.enabled_totals).forEach((item) => {
            value += item;
        });
        return value;
    }, [sourceState.totalCount]);

    return (
        <SourcesPageComponent
            groupedSources={sourceState.groupedSources}
            sourcesTotal={sourceState.sourcesTotal}
            onChangePage={onChangePage}
            limit={limit}
            page={page}
            editableSource={sourceState.editableSource}
            onSearch={onSearch}
            handleClickSort={handleClickSort}
            onChangeFilter={onChangeFilter}
            totalCount={sourceState.totalCount}
            totalEnabledSources={totalEnabledSources}
        />
    );
};
export default SourcesPage;
