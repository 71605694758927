import { Dispatch } from '@reduxjs/toolkit';
import SourceService from '../../../services/Source/SourceService';
import { AppThunk } from '../../store';
import { sourceActions } from '../index';

export const getAuthors = ():
AppThunk => (dispatch: Dispatch): void => {
    SourceService.fetchAuthors()
        .then((res) => {
            dispatch(sourceActions.setAuthors(res.data));
        });
};
