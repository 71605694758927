import {
    Alert,
} from 'antd';
import {
    IParserManagerSourceModel,
    IParserManagerSourceTaskModel,
} from '../../services/ParserManager/types';
import styles from './styles.module.scss';
import { SortOrder } from '../../services/types';
import TaskEditModal from './TaskEditModal';
import { ITableColumn } from '../../components/TempTable/types';
import TempTable from '../../components/TempTable';

interface IProps {
    sources: IParserManagerSourceModel[];
    isFetching: boolean;
    page: number;
    limit: number;
    onChangePage: (nextPage: number) => void;
    onSortTable: (
        column: ITableColumn<IParserManagerSourceModel>,
        direction: SortOrder | null
    ) => void;
    onSearchTable: (
        column: ITableColumn<IParserManagerSourceModel>,
        value: string
    ) => void;
    sourcesTotal: number;
    handleClickEditTask: (task: IParserManagerSourceTaskModel | null) => void;
    editableTask: IParserManagerSourceTaskModel | null;
    isError: boolean;
    columns: ITableColumn<IParserManagerSourceModel>[]
}

const ParserManagerComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        sources,
        isFetching,
        page,
        limit,
        onChangePage,
        onSortTable,
        onSearchTable,
        sourcesTotal,
        handleClickEditTask,
        editableTask,
        isError,
        columns,
    } = props;

    return (
        <div className={styles.wrapper}>
            <TempTable
                columns={columns}
                rows={sources ?? []}
                isLoading={isFetching}
                onSort={onSortTable}
                onSearch={onSearchTable}
                pagination={{
                    currentPage: page,
                    total: sourcesTotal,
                    onChange: onChangePage,
                    limit,
                }}
                className={styles.table}
            />

            {!isFetching && sources?.length < 1 && !isError && (
                <Alert
                    type="info"
                    message="Нет записей."
                />
            )}
            {isError && !isFetching && (
                <Alert
                    type="error"
                    message="Произошла ошибка при получении данных с сервера."
                />
            )}

            {Boolean(editableTask) && (
                <TaskEditModal
                    task={editableTask}
                    handleClickClose={() => handleClickEditTask(null)}
                />
            )}
        </div>
    );
};
export default ParserManagerComponent;
