import { Dispatch } from '@reduxjs/toolkit';
import { sourceActions } from '..';
import SourceService from '../../../services/Source/SourceService';
import { IGroupByAuthorParams } from '../../../services/Source/types';
import { AppThunk } from '../../store';

export const getGroupSourceByAuthor = (args: IGroupByAuthorParams):
AppThunk => (dispatch: Dispatch): void => {
    dispatch(sourceActions.setGroupedSourcesLoading(true));
    SourceService.groupByAuthor(args)
        .then((res) => {
            dispatch(sourceActions.setGroupedSources(res.data.data));
            dispatch(sourceActions.setGroupedSourcesLoading(false));
        });
};
