import { IGroupedSource, SourceTypeEnum } from '../../store/source/types';
import { GranularityEnum } from '../../store/types';
import { SortOrder } from '../types';

export interface IGroupByAuthorParams {
    page?: number;
    limit?: number;
    inactive_date?: string;
    search_params?: ISourceSearchSchema
    sort_params?: ISourceSortSchema
    filter_params?: ISourceFilterSchema
}

export interface IGroupByAuthorResponse {
    data: IGroupedSource[];
    total: number;
}

export interface ISourceSearchSchema {
    columns: ISourceSearchColumn[]
    pattern: string
}

export interface ISourceFilterSchema {
    location?: number[]
    enabled?: boolean
    allow_in_russia?: boolean
    auto_discover?: boolean
    source_type?: SourceTypeEnum[]
    parsed?: string
    created?: string
}

export enum ISourceSearchColumn {
    AUTHOR_TITLE = 'authors.title',
    SOURCE_TITLE = 'sources.title',
    SOURCE_LINK = 'sources.link',
}

export interface ISourceSortSchema {
    column: SourceSortColumn
    order: SortOrder
}

export enum SourceSortColumn {
    AUTHOR_ID = 'id',
    AUTHOR_TITLE = 'title',
}

export interface ISourceEditArgs {
    title: string;
    location_id: number;
    enabled: boolean;
    auto_discover: boolean;
    allow_in_russia: boolean;
    author_id: number;
}

export interface ICreateAuthorArgs {
    title: string;
    is_blogger: boolean
    is_reliable: boolean
    is_contracted: boolean
    logo_link: string
}

export interface ICreateArgs {
    author_id: number
    title: string
    link: string
    parse_link: string
    out_source_id: number
    location_id: number
    tag: string
    auto_discover: boolean
    allow_in_russia: boolean
    enabled: boolean
    type_id: number
}

export interface IGetDataByTagResponse {
    link: string
    out_source_id: number
    tag: string
    title: string
}

export interface IGetByDateArgs {
    period_num: number
    granularity: GranularityEnum
}

export type IGetByDateResponse = {
    dt: string
    auto_discover_true: number
    auto_discover_false: number
}[];

export interface IParsersInfoResponse {
    rss: {
        posts: number
        sources: number
    }
    vk: {
        posts: number
        sources: number
    }
    tg: {
        posts: number
        sources: number
    }
    ok: {
        posts: number
        sources: number
    }
}

export interface ISourceType {
    id: number
    name: string
}
