import { checkCookies } from 'cookies-next';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../../components/Layout';
import EventBusCookie from '../../utils/CheckCookie/EventBusCookie';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import Path from '../Path';

const ProtectedLayout: React.FC = (): React.ReactElement => {
    const [isAuth, setIsAuth] = useState(checkCookies(AUTH_COOKIE_NAME));

    const unauthorization = () => {
        if (isAuth) setIsAuth(false);
    };

    useEffect(() => {
        EventBusCookie.on('unauthorization', unauthorization);
        return () => {
            EventBusCookie.off('unauthorization', unauthorization);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isAuth) {
        return <Navigate to={Path.Auth} />;
    }

    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};
export default ProtectedLayout;
