enum Path {
    App = '/',
    Auth = '/auth',
    Sources = '/sources',
    Charts = '/charts',
    Authors = '/authors',
    ParserManagerSources = '/parser-manager-sources',
    ParserManagerTaskGroup = '/parser-manager-task-group',
    ParserManagerAccounts = '/parser-manager-accounts',
    ParserManagerProxy = '/parser-manager-proxy',
}

export default Path;
