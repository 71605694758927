import { AxiosResponse } from 'axios';
import $api from '../../api';
import { ApiRoutes } from '../../routes/ApiRoutes';
import {
    ICountByPlatformArgs,
    ICountByPlatformResponse,
    ICountCommentsByPlatformArgs,
    ICountCommentsByPlatformResponse,
    IGetAggregationByCategoryArgs,
    IGetAggregationByCategoryResponse,
    IGetRawResponse,
} from './types';

export default class PostService {
    static async countByPlatform(
        args: ICountByPlatformArgs,
    ): Promise<AxiosResponse<ICountByPlatformResponse>> {
        return $api.post(ApiRoutes.post.countByPlatform, args);
    }

    static getRawCounts(): Promise<AxiosResponse<IGetRawResponse>> {
        return $api.get(ApiRoutes.post.getRawCounts);
    }

    static getAggregationByCategory(
        args: IGetAggregationByCategoryArgs,
    ): Promise<AxiosResponse<IGetAggregationByCategoryResponse>> {
        return $api.post(ApiRoutes.post.getAggregationByCategory, args);
    }

    static countCommentsByPlatform(
        args: ICountCommentsByPlatformArgs,
    ): Promise<AxiosResponse<ICountCommentsByPlatformResponse>> {
        return $api.post(ApiRoutes.post.countCommentsByPlatform, args);
    }
}
