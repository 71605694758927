import { checkCookies } from 'cookies-next';
import { Navigate, Outlet } from 'react-router-dom';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import Path from '../Path';

const NonAuthLayout: React.FC = (): JSX.Element => {
    const isAuth = checkCookies(AUTH_COOKIE_NAME);

    if (isAuth) {
        return <Navigate to={Path.App} />;
    }

    return <Outlet />;
};

export default NonAuthLayout;
