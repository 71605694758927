import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Alert,
    Button, Modal, Popconfirm, Tooltip, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { authorsRtkQService } from '../../../services/Authors';
import {
    IAuthor,
    IGetAuthorsQueryParams,
    IGetAuthorsQuerySortColumn,
    IGetAuthorsQuerySortParams,
} from '../../../services/Authors/types';
import { SortOrder } from '../../../services/types';
import styles from './styles.module.scss';
import { ITableColumn } from '../../../components/TempTable/types';
import TempTable from '../../../components/TempTable';

const limit = 20;

interface IProps {
    handleClickAuthorEdit: (author: IAuthor) => void;
}

const AuthorsTable: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { handleClickAuthorEdit } = props;
    const [getAuthors, {
        data, isLoading, isSuccess, isFetching,
    }] = authorsRtkQService.useLazyPostFetchAuthorsQuery();

    const [
        deleteAuthor,
        {
            isLoading: isDeletingLoading,
            isSuccess: isDeletingSuccess,
            isError: isDeletingError,
        },
    ] = authorsRtkQService.useDeleteAuthorMutation();

    const [page, setPage] = useState<number>(1);
    const [sortParams, setSortParams] = useState<IGetAuthorsQuerySortParams | null>(null);

    useEffect(() => {
        const params: IGetAuthorsQueryParams = {
            page,
            limit,
        };
        if (sortParams) params.sort_params = sortParams;
        getAuthors(params);
    }, [page, getAuthors, sortParams]);

    const onChangePage = (nextPage: number): void => {
        setPage(nextPage);
    };

    const onSortTable = (
        column: ITableColumn<IAuthor>,
        order: SortOrder,
    ): void => {
        setSortParams({
            column: IGetAuthorsQuerySortColumn[column.dataIndex],
            order,
        });
    };

    const handleClickDelete = (authorId: number): void => {
        deleteAuthor({
            authorId,
        });
    };

    useEffect(() => {
        if (!isDeletingLoading && isDeletingSuccess) {
            Modal.success({
                title: 'Автор успешно удален.',
            });
        }
    }, [isDeletingLoading, isDeletingSuccess]);

    useEffect(() => {
        if (!isDeletingLoading && isDeletingError) {
            Modal.error({
                title: 'Произошла ошибка при удалении автора.',
            });
        }
    }, [isDeletingLoading, isDeletingError]);

    const columns: ITableColumn<IAuthor>[] = [
        {
            id: 1,
            title: 'ID',
            dataIndex: 'id',
            width: 100,
            sortable: true,
        },
        {
            id: 2,
            title: 'Заголовок',
            dataIndex: 'title',
            sortable: true,
        },
        {
            id: 3,
            title: 'Блогер',
            dataIndex: 'isBlogger',
            render: (value) => (
                <span>
                    {value ? 'Да' : 'Нет'}
                </span>
            ),
        },
        {
            id: 4,
            title: 'Кол-во источников',
            dataIndex: 'sourcesCnt',
        },
        {
            id: 5,
            title: null,
            dataIndex: 'id',
            render: (value, record) => (
                <div className={styles.rowActions}>
                    <Tooltip title="Редактировать">
                        <Button
                            onClick={() => handleClickAuthorEdit(record)}
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Вы уверены что хотите удалить автора?"
                        onConfirm={() => handleClickDelete(record.id)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button
                            type="primary"
                            shape="circle"
                            danger
                            icon={
                                <DeleteOutlined marginWidth={5} />
                            }
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div className={styles.wrapper}>
            {(isLoading || isSuccess) && (
                <Typography.Title level={4}>
                    Всего авторов:
                    {' '}
                    {data?.totalCnt}
                </Typography.Title>
            )}
            <TempTable
                columns={columns}
                rows={data?.authors ?? []}
                isLoading={isLoading || isDeletingLoading || isFetching}
                pagination={{
                    currentPage: page,
                    onChange: onChangePage,
                    total: data?.totalCnt,
                    limit,
                }}
                onSort={onSortTable}
            />

            {!isSuccess && !isLoading && (
                <Alert
                    type="error"
                    message="Произошла ошибка при получении данных с сервера."
                />
            )}
        </div>
    );
};
export default AuthorsTable;
