import {
    Button, Card, Form,
    Input, Typography,
} from 'antd';
import { checkCookies, setCookies } from 'cookies-next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Path from '../../routes/Path';
import AuthService from '../../services/Auth/AuthService';
import { AUTH_COOKIE_NAME } from '../../utils/constants';

const AuthPage = function AuthPage() {
    const isAuth = checkCookies(AUTH_COOKIE_NAME);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isAuthError, setIsAuthError] = useState(false);

    useEffect(() => {
        if (isAuth) navigate(Path.App);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    const handleClickFinish = async (values: any) => {
        setIsAuthError(false);
        try {
            const response = await AuthService.login({
                email: values.name,
                password: values.password,
            });

            if (response.data.token.length !== 0) {
                setCookies(AUTH_COOKIE_NAME, response.data.token);
                navigate(Path.App);
            }
        } catch (err) {
            window.console.log('error', err);
            setIsAuthError(true);
        }
    };

    return (
        <Card style={{ width: 520, margin: 'auto', marginTop: 100 }}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                style={{ margin: 'auto' }}
                onFinish={handleClickFinish}
            >
                <Typography.Title
                    level={3}
                    style={{ textAlign: 'center', marginTop: 28, marginBottom: 40 }}
                >
                    Вход в систему
                </Typography.Title>

                <Form.Item
                    hasFeedback
                    name="name"
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: 'Введите логин' }]}
                    label={<Typography.Text>Логин</Typography.Text>}
                >
                    <Input placeholder="" />
                </Form.Item>

                <Form.Item
                    hasFeedback
                    name="password"
                    style={{ marginBottom: 0, marginTop: 24 }}
                    rules={[{ required: true, message: 'Введите пароль' }]}
                    labelAlign="left"
                    label={<Typography.Text>Пароль</Typography.Text>}
                >
                    <Input.Password placeholder="" />
                </Form.Item>

                <Form.Item>
                    <Button
                        style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 24 }}
                        type="primary"
                        htmlType="submit"
                    >
                        Войти
                    </Button>
                    {isAuthError ? (
                        <Typography.Text
                            style={{ marginLeft: 24 }}
                            type="danger"
                        >
                            Неправильный логин или пароль
                        </Typography.Text>
                    ) : null}

                </Form.Item>
            </Form>
        </Card>
    );
};

export default AuthPage;
