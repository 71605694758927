import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import { FormikProps } from 'formik';
import AuthorSelect from '../../../components/AuthorSelect';
import Checkbox from '../../../components/common/Checkbox';
import Field from '../../../components/common/Field';
import LocationSelect from '../../../components/LocationSelect';
import SourceTypeSelect from '../../../components/SourceTypeSelect';
import { SourceTypeEnum } from '../../../store/source/types';
import styles from './styles.module.scss';
import { IValues } from './types';

interface IProps {
    isOpen: boolean;
    handleClickClose: () => void;
    handleClickSubmit: () => void;
    handleClickOpen: () => void;
    formik: FormikProps<IValues>;
    isLoading: boolean;
    step: 1 | 2;
    handleClickPrevStep: () => void;
}

const SourceAddModalComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        isOpen,
        handleClickClose,
        handleClickSubmit,
        handleClickOpen,
        formik,
        isLoading,
        step,
        handleClickPrevStep,
    } = props;

    return (
        <>
            <Button type="primary" onClick={handleClickOpen}>
                Добавить источник
            </Button>
            <Modal
                title="Добавление источника"
                visible={isOpen}
                onCancel={handleClickClose}
                footer={[
                    <Button
                        key="back"
                        onClick={handleClickPrevStep}
                        className={styles.buttonBack}
                        disabled={step === 1}
                    >
                        Назад
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleClickSubmit}
                        disabled={!formik.values.type_id && !formik.values.tag}
                    >
                        {step === 1 && 'Далее'}
                        {step === 2 && 'Подтвердить'}
                    </Button>,
                ]}
            >
                <div className={styles.form}>
                    {isLoading ? (
                        <div className={styles.spin}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <>
                            <SourceTypeSelect
                                value={formik.values.type_id}
                                onChange={(value) => formik.setFieldValue('type_id', value)}
                                error={!!formik.errors.type_id}
                                disabled={step === 2}
                            />

                            <div className={styles.inputWrapper}>
                                {formik.values.type_id === SourceTypeEnum.rss ? (
                                    <Field
                                        type="text"
                                        name="feed_url"
                                        value={formik.values.feed_url}
                                        onChange={formik.handleChange}
                                        placeholder="Feed url"
                                        disabled={step === 2}
                                    />
                                ) : (
                                    <Field
                                        type="text"
                                        name="tag"
                                        value={formik.values.tag}
                                        onChange={formik.handleChange}
                                        placeholder="Тег"
                                        disabled={step === 2}
                                    />
                                )}
                                <div className={styles.tagTooltip}>
                                    <QuestionCircleFilled />

                                    <div className={styles.tagTooltipContent}>
                                        <span>Пример:</span>

                                        <div
                                            className={
                                                styles.tagTooltipContentTable
                                            }
                                        >
                                            <div>
                                                <span>
                                                    https://t.me/antohakrasava
                                                </span>
                                                <span>antohakrasava</span>
                                            </div>
                                            <div>
                                                <span>
                                                    https://vk.com/rusnac2
                                                </span>
                                                <span>rusnac2</span>
                                            </div>
                                            <div>
                                                <span>
                                                    https://vk.com/id160635896
                                                </span>
                                                <span>id160635896</span>
                                            </div>
                                            <div>
                                                <span>
                                                    https://ok.ru/group52603206566093
                                                </span>
                                                <span>group52603206566093</span>
                                            </div>
                                            <div>
                                                <span>
                                                    https://ok.ru/fomajournal
                                                </span>
                                                <span>fomajournal</span>
                                            </div>
                                            <div>
                                                <span>
                                                    https://ok.ru/profile/246891717048
                                                </span>
                                                <span>246891717048</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {step === 2 && (
                                <>
                                    <Field
                                        type="text"
                                        name="title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        placeholder="Заголовок"
                                        error={!!formik.errors.title}
                                    />

                                    <Field
                                        type="text"
                                        name="link"
                                        value={formik.values.link}
                                        onChange={formik.handleChange}
                                        placeholder="Ссылка"
                                        error={!!formik.errors.link}
                                        disabled={step === 2 && formik.values.type_id !== SourceTypeEnum.rss}
                                    />

                                    <AuthorSelect
                                        onChange={(value) => formik.setFieldValue(
                                            'author_id',
                                            value,
                                        )}
                                        error={!!formik.errors.author_id}
                                    />

                                    <LocationSelect
                                        onChange={(value) => formik.setFieldValue(
                                            'location_id',
                                            value,
                                        )}
                                        error={!!formik.errors.location_id}
                                    />

                                    <div className={styles.checkboxes}>
                                        <Checkbox
                                            name="enabled"
                                            value={formik.values.enabled}
                                            onChange={formik.handleChange}
                                            label="Включено"
                                        />

                                        <Checkbox
                                            name="auto_discover"
                                            value={formik.values.auto_discover}
                                            onChange={formik.handleChange}
                                            label="Авто обнаружение"
                                        />

                                        <Checkbox
                                            name="allow_in_russia"
                                            value={
                                                formik.values.allow_in_russia
                                            }
                                            onChange={formik.handleChange}
                                            label="Доступно в РФ"
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};
export default SourceAddModalComponent;
