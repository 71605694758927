export const ApiRoutes = {
    source: {
        groupByAuthor: '/sources/get_source_groupby_author',
        getAuthors: '/authors/get_all_authors',
        edit: '/sources/',
        delete: '/sources/',
        getLocations: '/locations/get_all_locations',
        createAuthor: '/authors/',
        create: '/sources/add_source',
        types: '/sources/sources_types',
        getDataByTag: '/sources/get_source_id_by_tag',
        getByDate: '/sources/get_sources_by_date',
        parsersInfo: '/sources/parsers_info',
        getDataByFeedUrl: '/sources/check_feed',
        totalCount: 'sources/get_totals_cnt_sources',
    },
    post: {
        countByPlatform: 'posts/get_cnt_posts_by_platform',
        getRawCounts: 'posts/get_raw_counts',
        getAggregationByCategory: 'posts/get_aggregation_by_category',
        countCommentsByPlatform: 'comments/get_cnt_comments_by_platform',
    },
    auth: {
        login: '/auth',
    },
};
