import axios, { AxiosRequestConfig } from 'axios';
import { getCookie, removeCookies, setCookies } from 'cookies-next';
import { ADMIN_API_URL } from '../utils/api';
import newCheckCookies from '../utils/CheckCookie';
import { AUTH_COOKIE_NAME } from '../utils/constants';

const $instance = axios.create({
    baseURL: ADMIN_API_URL,
    withCredentials: true,
});

$instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const accessToken = getCookie('Authorization');

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});

const refreshAuthLogic = () => $instance
    .post('/check_token', {})
    .then((response) => {
        setCookies(AUTH_COOKIE_NAME, response.data.token);
        return Promise.resolve();
    })
    .catch((error) => {
        removeCookies(AUTH_COOKIE_NAME);
        newCheckCookies(AUTH_COOKIE_NAME);
        return Promise.reject(error);
    });

export default refreshAuthLogic;
