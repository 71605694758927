import { AxiosResponse } from 'axios';
import $api from '../../api';
import { ApiRoutes } from '../../routes/ApiRoutes';
import {
    IAuthor,
    ILocation,
    ISource, ISourcesTotalCount, ISourceType,
    SourceTypeEnum,
} from '../../store/source/types';
import {
    ICreateArgs,
    ICreateAuthorArgs,
    IGetByDateArgs,
    IGetByDateResponse,
    IGetDataByTagResponse,
    IGroupByAuthorParams,
    IGroupByAuthorResponse,
    IParsersInfoResponse,
    ISourceEditArgs,
} from './types';

// OLD SERVICE

export default class SourceService {
    static async groupByAuthor(
        args: IGroupByAuthorParams,
    ): Promise<AxiosResponse<IGroupByAuthorResponse>> {
        return $api.post(ApiRoutes.source.groupByAuthor, args);
    }

    static async fetchAuthors(): Promise<AxiosResponse<IAuthor[]>> {
        return $api.get(ApiRoutes.source.getAuthors);
    }

    static async fetchLocations(): Promise<AxiosResponse<ILocation[]>> {
        return $api.get(ApiRoutes.source.getLocations);
    }

    static async edit(
        sourceId: number,
        args: ISourceEditArgs,
    ): Promise<AxiosResponse<ISource>> {
        return $api.put(`${ApiRoutes.source.edit}${sourceId}`, args);
    }

    static async createAuthor(
        args: ICreateAuthorArgs,
    ): Promise<AxiosResponse<IAuthor>> {
        return $api.post(ApiRoutes.source.createAuthor, args);
    }

    static async create(args: ICreateArgs): Promise<AxiosResponse<ISource>> {
        return $api.post(ApiRoutes.source.create, args);
    }

    static async fetchTypes(): Promise<AxiosResponse<ISourceType[]>> {
        return $api.get(ApiRoutes.source.types);
    }

    static async delete(sourceId: number): Promise<AxiosResponse> {
        return $api.delete(`${ApiRoutes.source.delete}${sourceId}`);
    }

    static async getDataByTag(
        type: SourceTypeEnum,
        tag: string,
    ): Promise<AxiosResponse<IGetDataByTagResponse>> {
        return $api.post(ApiRoutes.source.getDataByTag, {
            source_type: type,
            tag,
        });
    }

    static async getByDate(
        args: IGetByDateArgs,
    ): Promise<AxiosResponse<IGetByDateResponse>> {
        return $api.post(ApiRoutes.source.getByDate, args);
    }

    static async parsersInfo(): Promise<AxiosResponse<IParsersInfoResponse>> {
        return $api.get(ApiRoutes.source.parsersInfo);
    }

    static getDataByFeedUrl(
        feedUrl: string,
    ): Promise<AxiosResponse<IGetDataByTagResponse>> {
        return $api.post(ApiRoutes.source.getDataByFeedUrl, {
            feed_url: feedUrl,
        });
    }

    static async totalCount(
        args: IGroupByAuthorParams,
    ): Promise<AxiosResponse<ISourcesTotalCount>> {
        return $api.post(ApiRoutes.source.totalCount, args);
    }
}
