import {
    Button, Drawer, Select, Typography,
} from 'antd';
import { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { IParserManagerTaskGroupFilter } from './types';
import { parserManagerRtkQService } from '../../../services/ParserManager';

interface IProps {
    onChange: (data: IParserManagerTaskGroupFilter) => void;
}

const ParserManagerTaskGroupFilter: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { onChange } = props;
    const { data: taskTypes, isFetching: isTaskTypesFetching } = parserManagerRtkQService.useGetTaskTypesQuery();
    const { data: statuses, isFetching: isStatusesFetching } = parserManagerRtkQService.useGetStatusesQuery();

    const [isVisible, setVisible] = useState<boolean>(false);
    const [statusId, setStatusId] = useState<number | null>(null);
    const [taskTypeId, setTaskTypeId] = useState<number | null>(null);

    const showDrawer = (): void => setVisible(true);
    const closeDrawer = (): void => setVisible(false);

    const clearFilter = (): void => {
        setStatusId(null);
        setTaskTypeId(null);
    };

    const handleClickClear = (): void => {
        closeDrawer();
        clearFilter();
        onChange(null);
    };

    const handleClickConfirm = (): void => {
        const args: IParserManagerTaskGroupFilter = {};

        if (statusId !== null) args.statusId = statusId;
        if (taskTypeId !== null) args.taskTypeId = taskTypeId;

        onChange(args);
        closeDrawer();
    };

    const statusOptions = useMemo(() => {
        if (isStatusesFetching || !statuses || statuses?.length < 1) return [];
        return statuses.map((status) => ({
            value: String(status.id),
            label: status.name,
        }));
    }, [isStatusesFetching, statuses]);

    const handleChangeStatus = (value: string): void => {
        setStatusId(Number(value));
    };

    const taskTypesOptions = useMemo(() => {
        if (isTaskTypesFetching || !taskTypes || taskTypes?.length < 1) return [];
        return taskTypes.map((taskType) => ({
            value: String(taskType.id),
            label: taskType.description,
        }));
    }, [isTaskTypesFetching, taskTypes]);

    const handleChangeTaskType = (value: string): void => {
        setTaskTypeId(Number(value));
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer} style={{ width: 100 }}>
                Фильтры
            </Button>
            <Drawer
                title="Фильтры"
                placement="left"
                onClose={closeDrawer}
                visible={isVisible}
            >
                <div className={styles.container}>
                    <div className={styles.block}>
                        <Typography.Text>Статус</Typography.Text>
                        <Select
                            placeholder="Выберите статус"
                            value={statusId !== null ? String(statusId) : statusId}
                            onChange={handleChangeStatus}
                            options={statusOptions}
                        />
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Тип задачи</Typography.Text>
                        <Select
                            placeholder="Выберите тип задачи"
                            value={taskTypeId !== null ? String(taskTypeId) : taskTypeId}
                            onChange={handleChangeTaskType}
                            options={taskTypesOptions}
                        />
                    </div>

                    <div className={styles.actions}>
                        <Button onClick={handleClickClear}>Очистить</Button>
                        <Button type="primary" onClick={handleClickConfirm}>
                            Применить
                        </Button>
                    </div>
                </div>
            </Drawer>
        </>
    );
};
export default ParserManagerTaskGroupFilter;
