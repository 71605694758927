import {
    Button, Modal, Spin, Typography,
} from 'antd';
import { FormikProps } from 'formik';
import AuthorSelect from '../../../components/AuthorSelect';
import Checkbox from '../../../components/common/Checkbox';
import Field from '../../../components/common/Field';
import LocationSelect from '../../../components/LocationSelect';
import { ISourceEditArgs } from '../../../services/Source/types';
import { ISource } from '../../../store/source/types';
import styles from './styles.module.scss';

interface IProps {
    handleClickClose: () => void;
    handleClickSubmit: () => void;
    source: ISource | null;
    formik: FormikProps<ISourceEditArgs>;
    isLoading: boolean
    response: ISource
}

const SourceEditModalComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        handleClickClose, handleClickSubmit, source, formik,
        isLoading, response,
    } = props;

    return (
        <Modal
            title="Редактирование источника"
            visible
            onCancel={handleClickClose}
            footer={[
                <Button key="back" onClick={handleClickClose}>
                    Отмена
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleClickSubmit}
                >
                    Подтвердить
                </Button>,
            ]}
        >
            {source && (
                <div className={styles.form}>
                    {isLoading
                        ? (
                            <div className={styles.spin}>
                                <Spin />
                            </div>
                        )
                        : (
                            <>
                                <AuthorSelect
                                    value={source.author_id}
                                    onChange={(value) => formik.setFieldValue('author_id', value)}
                                />

                                <LocationSelect
                                    value={source.location_id}
                                    onChange={(value) => formik.setFieldValue('location_id', value)}
                                />

                                <Field
                                    type="text"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    placeholder="Заголовок"
                                    error={!!formik.errors.title}
                                />

                                <div className={styles.checkboxes}>
                                    <Checkbox
                                        name="enabled"
                                        value={formik.values.enabled}
                                        onChange={formik.handleChange}
                                        label="Включено"
                                    />

                                    <Checkbox
                                        name="auto_discover"
                                        value={formik.values.auto_discover}
                                        onChange={formik.handleChange}
                                        label="Авто обнаружение"
                                    />

                                    <Checkbox
                                        name="allow_in_russia"
                                        value={formik.values.allow_in_russia}
                                        onChange={formik.handleChange}
                                        label="Доступно в РФ"
                                    />
                                </div>

                                {response && (
                                    <Typography.Text type="success">
                                        Источник успешно обновлен. ID:
                                        {response.id}
                                    </Typography.Text>
                                )}
                            </>
                        )}
                </div>
            )}
        </Modal>
    );
};
export default SourceEditModalComponent;
