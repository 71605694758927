/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

type CallbackFn = () => void;

const useDebounce = (
    callback: CallbackFn,
    delay: number,
    dependencies: any[],
): void => {
    useEffect(() => {
        let handler: NodeJS.Timeout | null = null;

        const debouncedFn = () => {
            if (handler) {
                clearTimeout(handler);
            }
            handler = setTimeout(() => {
                callback();
            }, delay);
        };

        debouncedFn();

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [...dependencies, delay]);
};

export default useDebounce;
