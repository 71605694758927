import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { ChangeEvent, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
    onSubmit: (value: string) => void;
}

const InputSearch: React.FC<Props> = (props: Props): React.ReactElement => {
    const { onSubmit } = props;
    const [value, setValue] = useState<string>('');

    const handleSubmit = () => onSubmit(value);

    const onClear = () => onSubmit('');

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.type === 'click') {
            onClear();
        }
        setValue(event.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <Input size="small" value={value} allowClear onChange={onChange} />
            <Button
                type="primary"
                size="small"
                shape="default"
                icon={<SearchOutlined />}
                onClick={handleSubmit}
            />
        </div>
    );
};
export default InputSearch;
