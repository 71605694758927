import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { parserManagerRtkQService } from '../../services/ParserManager';
import ParserManagerProxyComponent from './component';
import { ITableColumn } from '../../components/TempTable/types';
import {
    IGetProxyOrderColumns, IParserManagerProxyModel, IParserManagerProxyType, IParserManagerStatuses, IQueryParamsGetProxy,
} from '../../services/ParserManager/types';
import styles from './styles.module.scss';
import { SortOrder } from '../../services/types';

const ParserManagerProxy : React.FC = (): React.ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState<number>(() => Number(searchParams.get('page')));
    const navigate = useNavigate();
    const location = useLocation();
    const [filterStatus, setFilterStatus] = useState<IParserManagerStatuses | null>(null);
    const [filterProxyType, setFilterProxyType] = useState<IParserManagerProxyType | null>(null);
    const [sort, setSort] = useState<{
        column: IGetProxyOrderColumns;
        order: SortOrder;
    } | null>(null);
    const [getProxy, { data: proxy, isFetching, isError }] = parserManagerRtkQService.useLazyGetProxyQuery();

    useEffect(() => {
        const pageValue = Number(searchParams.get('page'));
        if (pageValue === 0) {
            navigate(`${location.pathname}?page=1`);
        }
        setPage(pageValue);
    }, [searchParams, navigate, location]);

    const args = useMemo(() => {
        const params: IQueryParamsGetProxy = {};

        if (sort) {
            params.order_by = sort.column;
            params.order_direction = sort.order;
        }

        if (filterStatus) params.status = filterStatus;
        if (filterProxyType) params.proxy_type = filterProxyType;

        return params;
    }, [sort, filterStatus, filterProxyType]);

    useEffect(() => {
        if (page) {
            getProxy({
                ...args,
                page: Number(page),
            });
        }
    }, [page, getProxy, args]);

    const onSortTable = (
        column: ITableColumn<IParserManagerProxyModel>,
        order: SortOrder,
    ): void => {
        setSort({
            column: IGetProxyOrderColumns[column.dataIndex],
            order,
        });
    };

    const handleChangeStatus = (e: RadioChangeEvent): void => {
        setFilterStatus(e.target.value);
    };

    const handleChangeProxyType = (e: RadioChangeEvent): void => {
        setFilterProxyType(e.target.value);
    };

    const onChangePage = (nextPage: number): void => {
        setSearchParams({ page: String(nextPage) });
    };

    const columns: ITableColumn<IParserManagerProxyModel>[] = useMemo(() => [
        {
            id: 1,
            dataIndex: 'id',
            title: 'ID',
            width: 100,
            sortable: true,
        },
        {
            id: 2,
            dataIndex: 'status',
            title: 'Статус',
            width: 125,
            renderFilter: () => (
                <div className={styles.filterStatus}>
                    <Radio.Group onChange={handleChangeStatus} value={filterStatus}>
                        <Space direction="vertical">
                            <Radio value={null}>Не учитывать</Radio>
                            <Radio value={IParserManagerStatuses.available}>available</Radio>
                            <Radio value={IParserManagerStatuses.temporaryBan}>temporary ban</Radio>
                            <Radio value={IParserManagerStatuses.permanentBan}>permanent ban</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            ),
        },
        {
            id: 3,
            dataIndex: 'bannedUntil',
            title: 'Бан',
            sortable: true,
            render: (dateString: string) => (
                <span>{dateString ? new Date(dateString).toLocaleString() : 'Нет информации'}</span>
            ),
        },
        {
            id: 4,
            dataIndex: 'lastUsed',
            title: 'Послед. использование',
            sortable: true,
            render: (dateString: string) => (
                <span>{dateString ? new Date(dateString).toLocaleString() : 'Нет информации'}</span>
            ),
        },
        {
            id: 5,
            dataIndex: 'failedCounter',
            title: 'Неудач. попытки',
            sortable: true,
        },
        {
            id: 6,
            dataIndex: 'proxyType',
            title: 'Тип',
            renderFilter: () => (
                <div className={styles.filterStatus}>
                    <Radio.Group onChange={handleChangeProxyType} value={filterProxyType}>
                        <Space direction="vertical">
                            <Radio value={null}>Не учитывать</Radio>
                            <Radio value={IParserManagerProxyType.http}>http</Radio>
                            <Radio value={IParserManagerProxyType.socks}>socks</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            ),
        },
        {
            id: 7,
            dataIndex: 'credentials',
            title: 'Данные',
            render: (credentials) => (
                <div className={styles.credentials}>
                    {Object.keys(credentials).map((key) => <span key={key}>{`${key}: ${credentials[key]}`}</span>)}
                </div>
            ),
        },
    ], [filterStatus, filterProxyType]);

    return (
        <ParserManagerProxyComponent
            columns={columns}
            isFetching={isFetching}
            proxyData={proxy?.data}
            total={proxy?.total}
            page={page}
            onChangePage={onChangePage}
            isError={isError}
            onSortTable={onSortTable}
        />
    );
};
export default ParserManagerProxy;
