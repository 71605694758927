export interface ISourceState {
    groupedSources: IGroupedSource[] | null
    sourcesTotal: number
    editableSource: ISource | null
    authors: IAuthor[] | null
    locations: ILocation[] | null
    types: ISourceType[] | null
    typesLoadingStatus: boolean
    groupedSourcesLoading: boolean
    totalCount: ISourcesTotalCount | null
}

export interface IGroupedSource extends IAuthor {
    sources: ISource[]
}

export interface ISource {
    id: number
    title: string
    link: string
    parse_link: string
    parsed: string
    created: string
    out_source_id: string
    location: string
    location_id: number
    tag: string
    enabled: boolean
    auto_discover: boolean
    allow_in_russia: boolean
    type: string
    author_id: number | null | undefined
}

export interface IAuthor {
    id: number
    title: string
    is_blogger: boolean
    is_reliable: boolean
    is_contracted: boolean
    logo_link: string | null
}

export interface ILocation {
    id: number
    title: string
}

export interface ISourceType {
    id: number
    title: string
}

export enum SourceTypeEnum {
    rss = 1,
    vk = 2,
    tg = 3,
    ok = 4,
    dzen = 5,
    rutube = 6,
}

export interface ISourcesTotalCount {
    total: number
    totals: {
        [key: string]: number
    }
    enabled_totals: {
        [key: string]: number
    }
}
