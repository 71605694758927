import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import classnames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Path from '../../../routes/Path';
import NarrowMenu from '../../NarrowMenu';
import ArrowsMenuIcon from './arrows-menu.svg';
import IciLogo from './ici-logo.svg';
import styles from './styles.module.scss';

const NavBar: React.FC = (): React.ReactElement => {
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const onExpandMenu = (): void => setExpanded((prev) => !prev);

    return (
        <aside
            className={classnames(
                styles.wrapper,
                styles[isExpanded ? 'expanded' : 'normal'],
            )}
        >
            <Link to={Path.App} className={styles.logoLink}>
                <img src={IciLogo} alt="IciLogo" />
                {/* <IciLogo /> */}
            </Link>

            <div className={styles.arrowsContainer}>
                <button
                    type="button"
                    className={classnames(
                        styles.arrowsContainer_arrows,
                        styles[isExpanded ? 'expanded' : 'normal'],
                    )}
                    onClick={onExpandMenu}
                >
                    <img src={ArrowsMenuIcon} alt="arrows" />
                </button>
            </div>

            <NarrowMenu menuExpanded={isExpanded} />

            <div className={styles.footer}>
                <Link to="/" className={styles.avatarLink}>
                    <Avatar size={35} icon={<UserOutlined />} />
                </Link>
            </div>
        </aside>
    );
};
export default NavBar;
