import { FormikProps, useFormik } from 'formik';
import { useState } from 'react';
import SourceService from '../../services/Source/SourceService';
import { ICreateAuthorArgs } from '../../services/Source/types';
import { useAppDispatch } from '../../store/hooks';
import { sourceActions } from '../../store/source';
import { IAuthor } from '../../store/source/types';
import AuthorAddModalComponent from './component';
import validationScheme from './validationSchema';

interface IProps {
    visible?: boolean;
    onClose: () => void;
}

const AuthorAddModal: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { visible, onClose } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<IAuthor | null>(null);

    const formik: FormikProps<ICreateAuthorArgs> = useFormik<ICreateAuthorArgs>(
        {
            initialValues: {
                is_blogger: false,
                is_contracted: false,
                is_reliable: false,
            } as ICreateAuthorArgs,
            validationSchema: validationScheme,
            validateOnChange: false,

            onSubmit: async (values: ICreateAuthorArgs) => {
                setLoading(true);
                const res = await SourceService.createAuthor(values);
                dispatch(sourceActions.addAuthor(res.data));
                setResponse(res.data);
                setLoading(false);
                formik.resetForm();
            },
        },
    );

    const handleClickClose = () => {
        onClose();
        setResponse(null);
    };

    return (
        <AuthorAddModalComponent
            onClickClose={handleClickClose}
            visible={visible}
            formik={formik}
            isLoading={isLoading}
            response={response}
        />
    );
};
export default AuthorAddModal;
