import { rtkQApi } from '..';
import {
    IAuthor, IAuthorApi, IEditAuthorQueryParams, IGetAuthorsQueryParams, IGetAuthorsQueryResponse,
} from './types';

export const authorsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postFetchAuthors: build.query<IGetAuthorsQueryResponse, IGetAuthorsQueryParams>({
            query: (body) => ({
                url: 'authors/get_authors',
                method: 'POST',
                body,
            }),
            transformResponse: (baseQueryResponse: { total_cnt: number; data: IAuthorApi[] }): IGetAuthorsQueryResponse => {
                const authors = baseQueryResponse.data.map((item) => ({
                    id: item.id,
                    title: item.title,
                    isBlogger: item.is_blogger,
                    sourcesCnt: item.sources_cnt,
                } as IAuthor));

                return {
                    totalCnt: baseQueryResponse.total_cnt,
                    authors,
                };
            },
            providesTags: ['AuthorsList'],
        }),
        deleteAuthor: build.mutation<void, { authorId: number }>({
            query: (body) => ({
                url: `authors/${body.authorId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AuthorsList'],
        }),
        putEditAuthor: build.mutation<void, { authorId: number, params: IEditAuthorQueryParams }>({
            query: (body) => ({
                url: `authors/${body.authorId}`,
                method: 'PUT',
                body: {
                    title: body.params.title,
                    is_blogger: body.params.isBlogger,
                },
            }),
            invalidatesTags: ['AuthorsList'],
        }),
    }),
});
