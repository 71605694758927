import * as Yup from 'yup';

const validationScheme = Yup.object({
    title: Yup.string().required(),
    enabled: Yup.boolean().required(),
    auto_discover: Yup.boolean().required(),
    allow_in_russia: Yup.boolean().required(),
    location_id: Yup.number().required(),
    author_id: Yup.number().required(),
});

export default validationScheme;
