import { Moment } from 'moment';
import { ISourceFilterSchema } from '../../../services/Source/types';

export enum FilterBooleanOptions {
    disabled = 0,
    enabled = 1,
    notUse = 2,
}

export interface ISourceFilterData {
    filterParams: ISourceFilterSchema | null
    inactiveDate: Moment | null
}
