export interface ISimpleEntity {
    label: string;
    value: number;
}

export enum GranularityEnum {
    year = 'YEAR',
    month = 'MONTH',
    week = 'WEEK',
    day = 'DAY',
}
