import { rtkQApi } from '..';
import {
    IGetSourcesQueryParams,
    IGetTaskGroupQueryParams,
    IGetTasksQueryParams,
    IParserManagerAccountFromApi,
    IParserManagerAccountModel,
    IParserManagerProxyFromApi,
    IParserManagerProxyModel,
    IParserManagerSourceFromApi,
    IParserManagerSourceModel,
    IParserManagerSourceTaskFromApi,
    IParserManagerSourceTaskModel,
    IParserManagerStatus,
    IParserManagerTaskEditParams,
    IParserManagerTaskFromApi,
    IParserManagerTaskGroupFromApi,
    IParserManagerTaskGroupInfoFromApi,
    IParserManagerTaskGroupInfoModel,
    IParserManagerTaskGroupModel,
    IParserManagerTaskModel,
    IParserManagerTaskType,
    IQueryParamsGetAccountList,
    IQueryParamsGetProxy,
} from './types';

export const parserManagerRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getSources: build.query<{ total: number, data: IParserManagerSourceModel[] }, IGetSourcesQueryParams>({
            query: (body) => ({
                url: `parser_manager/sources/?${new URLSearchParams(
                    Object.entries(body),
                ).toString()}`,
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: { total: number, data: IParserManagerSourceFromApi[] },
            ): { total: number, data: IParserManagerSourceModel[] } => {
                const arr = baseQueryResponse.data.map(
                    (item) => ({
                        id: item.id,
                        parseLink: item.parse_link,
                        tag: item.tag,
                        allowInRussia: item.allow_in_russia,
                        link: item.link,
                        title: item.title,
                        outSourceId: item.out_source_id,
                        sourceTypeId: item.source_type_id,
                        parseParams: item.parse_params.map(
                            (parseParamItem) => ({
                                taskTypeId: parseParamItem.task_type_id,
                                sourceId: parseParamItem.source_id,
                                statusId: parseParamItem.status_id,
                                lastParse: parseParamItem.last_parse,
                                frequencyParse:
                                            parseParamItem.frequency_parse,
                                enabled: parseParamItem.enabled,
                            }),
                        ),
                    } as IParserManagerSourceModel),
                );
                return {
                    total: baseQueryResponse.total,
                    data: arr,
                };
            },
            providesTags: ['SourcesList'],
        }),
        getTaskTypes: build.query<IParserManagerTaskType[], void>({
            query: () => ({
                url: 'parser_manager/dictionary/task_types',
                method: 'GET',
            }),
        }),
        getStatuses: build.query<IParserManagerStatus[], void>({
            query: () => ({
                url: 'parser_manager/dictionary/statuses',
                method: 'GET',
            }),
        }),
        getTasks: build.query<{ total: number, data: IParserManagerTaskModel[] }, IGetTasksQueryParams>({
            query: (body) => {
                // @ts-ignore
                const params = new URLSearchParams(Object.entries(body.args)).toString();
                return {
                    url: `parser_manager/sources/${body.sourceId}/task_type/${body.taskTypeId}/tasks?${params}`,
                    method: 'GET',
                };
            },
            transformResponse: (
                baseQueryResponse: { total: number, data: IParserManagerTaskFromApi[] },
            ): { total: number, data: IParserManagerTaskModel[] } => {
                const arr = baseQueryResponse.data.map(
                    (item) => ({
                        id: item.id,
                        groupId: item.group_id,
                        statusId: item.status_id,
                        statusCode: item.status_code,
                        finishedAt: item.finished_at,
                        expiredAt: item.expired_at,
                        additionalData: item.additional_data,
                        postId: item.post,
                        post: item.post,
                        sourceId: item.source_id,
                    } as IParserManagerTaskModel),
                );
                return {
                    total: baseQueryResponse.total,
                    data: arr,
                };
            },
        }),
        putEditTask: build.mutation<IParserManagerSourceTaskModel, IParserManagerTaskEditParams>({
            query: (body) => ({
                url: `parser_manager/sources/${body.sourceId}/task_type/${body.taskTypeId}`,
                method: 'PUT',
                body: {
                    frequency_parse: body.frequencyParse,
                },
            }),
            transformResponse: (
                baseQueryResponse: IParserManagerSourceTaskFromApi,
            ): IParserManagerSourceTaskModel => ({
                taskTypeId: baseQueryResponse.task_type_id,
                sourceId: baseQueryResponse.source_id,
                statusId: baseQueryResponse.status_id,
                lastParse: baseQueryResponse.last_parse,
                frequencyParse: baseQueryResponse.frequency_parse,
                enabled: baseQueryResponse.enabled,
            }),
            invalidatesTags: ['SourcesList'],
        }),
        getTaskGroup: build.query<{ total: number, data: IParserManagerTaskGroupModel[] }, IGetTaskGroupQueryParams>({
            query: (body) => {
                const params = new URLSearchParams(Object.entries(body)).toString();
                return {
                    url: `parser_manager/task_group/?${params}`,
                    method: 'GET',
                };
            },
            transformResponse: (
                baseQueryResponse: { total: number, data: IParserManagerTaskGroupFromApi[] },
            ): { total: number, data: IParserManagerTaskGroupModel[] } => {
                const arr = baseQueryResponse.data.map(
                    (item) => ({
                        id: item.id,
                        statusId: item.status_id,
                        finishedAt: item.finished_at,
                        createAt: item.create_at,
                        taskTypeId: item.task_type_id,
                    } as IParserManagerTaskGroupModel),
                );
                return {
                    total: baseQueryResponse.total,
                    data: arr,
                };
            },
        }),
        getTaskGroupById: build.query<IParserManagerTaskGroupInfoModel, { id: number }>({
            query: (body) => ({
                url: `parser_manager/task_group/${body.id}`,
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: IParserManagerTaskGroupInfoFromApi,
            ): IParserManagerTaskGroupInfoModel => {
                const tasks = baseQueryResponse.tasks.map(
                    (item) => ({
                        id: item.id,
                        groupId: item.group_id,
                        statusId: item.status_id,
                        statusCode: item.status_code,
                        finishedAt: item.finished_at,
                        expiredAt: item.expired_at,
                        additionalData: item.additional_data,
                        postId: item.post,
                        post: item.post,
                        sourceId: item.source_id,
                        source: {
                            id: item.source.id,
                            parseLink: item.source.parse_link,
                            tag: item.source.tag,
                            allowInRussia: item.source.allow_in_russia,
                            link: item.source.link,
                            title: item.source.title,
                            outSourceId: item.source.out_source_id,
                            sourceTypeId: item.source.source_type_id,
                            parseParams: item.source.parse_params.map(
                                (parseParamItem) => ({
                                    taskTypeId: parseParamItem.task_type_id,
                                    sourceId: parseParamItem.source_id,
                                    statusId: parseParamItem.status_id,
                                    lastParse: parseParamItem.last_parse,
                                    frequencyParse:
                                                    parseParamItem.frequency_parse,
                                    enabled: parseParamItem.enabled,
                                }),
                            ),
                        },
                    } as IParserManagerTaskModel),
                );

                return {
                    id: baseQueryResponse.id,
                    statusId: baseQueryResponse.status_id,
                    finishedAt: baseQueryResponse.finished_at,
                    createAt: baseQueryResponse.create_at,
                    taskTypeId: baseQueryResponse.task_type_id,
                    tasks,
                };
            },
        }),
        getAccountList: build.query<{ data: IParserManagerAccountModel[], total: number }, IQueryParamsGetAccountList>({
            query: (body) => {
                const params = new URLSearchParams(Object.entries(body)).toString();
                return {
                    url: `parser_manager/account/list?${params}`,
                    method: 'GET',
                };
            },
            transformResponse: (
                baseQueryResponse: { data: IParserManagerAccountFromApi[], total: number },
            ): { data: IParserManagerAccountModel[], total: number } => {
                const accounts = baseQueryResponse.data.map(
                    (item) => ({
                        sourceTypeId: item.source_type_id,
                        id: item.id,
                        failedCounter: item.failed_counter,
                        createdAt: item.created_at,
                        lastUsed: item.last_used,
                        banInfo: item.ban_info,
                        bannedUntil: item.banned_until,
                        status: item.status,
                        credentials: item.credentials,
                    } as IParserManagerAccountModel),
                );

                return {
                    data: accounts,
                    total: baseQueryResponse.total,
                };
            },
        }),
        getProxy: build.query<{ data: IParserManagerProxyModel[], total: number }, IQueryParamsGetProxy>({
            query: (body) => {
                const params = new URLSearchParams(Object.entries(body)).toString();
                return {
                    url: `parser_manager/proxy/all?${params}`,
                    method: 'GET',
                };
            },
            transformResponse: (
                baseQueryResponse: { data: IParserManagerProxyFromApi[], total: number },
            ): { data: IParserManagerProxyModel[], total: number } => {
                const accounts = baseQueryResponse.data.map(
                    (item) => ({
                        id: item.id,
                        proxyType: item.proxy_type,
                        credentials: {
                            host: item.credentials.host,
                            port: item.credentials.port,
                            username: item.credentials.username,
                            password: item.credentials.password,
                        },
                        status: item.status,
                        failedCounter: item.failed_counter,
                        lastUsed: item.last_used,
                        bannedUntil: item.banned_until,
                    } as IParserManagerProxyModel),
                );

                return {
                    data: accounts,
                    total: baseQueryResponse.total,
                };
            },
        }),
    }),
});
