import SvgIcon from '../../SvgIcon';
import styles from './styles.module.scss';
import { SortOrder } from '../../../../services/types';

interface IProps {
    onSort?: (order: SortOrder) => void;
    type?: 'number' | 'text';
    activeOrder?: SortOrder | null
}

const TableSort: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { onSort, type, activeOrder } = props;
    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>Сортировка</p>

            {type === 'number' && (
                <div className={styles.numberSort}>
                    <button
                        type="button"
                        onClick={() => onSort(SortOrder.desc)}
                    >
                        <SvgIcon id="SortingIcon" size={16} />
                        <span>По убыванию</span>
                    </button>
                    <button type="button" onClick={() => onSort(SortOrder.asc)}>
                        <SvgIcon id="SortingIcon" size={16} />
                        <span>По возрастанию</span>
                    </button>
                </div>
            )}
            {type === 'text' && (
                <div className={styles.textSort}>
                    <button type="button" data-active={activeOrder === SortOrder.desc} onClick={() => onSort(SortOrder.desc)}>
                        <SvgIcon id="SortingIcon" size={16} />
                        <p>
                            От
                            <span>A</span>
                            до Я
                        </p>
                    </button>
                    <button type="button" data-active={activeOrder === SortOrder.asc} onClick={() => onSort(SortOrder.asc)}>
                        <SvgIcon id="SortingIcon" size={16} />
                        <p>
                            От
                            <span>Я</span>
                            до A
                        </p>
                    </button>
                </div>
            )}
        </div>
    );
};
export default TableSort;
