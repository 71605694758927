import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IProps {
    name: string;
    value: boolean;
    onChange?: (e: CheckboxChangeEvent) => void;
    label: string;
}

const Checkbox: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        name, value, onChange, label,
    } = props;

    return (
        <AntdCheckbox onChange={onChange} name={name} checked={value}>
            {label}
        </AntdCheckbox>
    );
};
export default Checkbox;
