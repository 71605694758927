import { Dispatch } from '@reduxjs/toolkit';
import { sourceActions } from '..';
import SourceService from '../../../services/Source/SourceService';
import { IGroupByAuthorParams } from '../../../services/Source/types';
import { AppThunk } from '../../store';

export const getSourcesTotalCount = (args: IGroupByAuthorParams):
AppThunk => (dispatch: Dispatch): void => {
    SourceService.totalCount(args)
        .then((res) => {
            dispatch(sourceActions.setTotalCount(res.data));
            dispatch(sourceActions.setSourcesTotal(res.data.total));
        });
};
