import {
    Button, Modal, Spin, Typography,
} from 'antd';
import { FormikProps } from 'formik';
import { ICreateAuthorArgs } from '../../services/Source/types';
import { IAuthor } from '../../store/source/types';
import Checkbox from '../common/Checkbox';
import Field from '../common/Field';
import styles from './styles.module.scss';

interface IProps {
    onClickClose: () => void;
    visible: boolean;
    formik: FormikProps<ICreateAuthorArgs>;
    isLoading: boolean;
    response: IAuthor;
}

const AuthorAddModalComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        onClickClose, visible, formik, isLoading, response,
    } = props;

    return (
        <Modal
            title="Добавление автора"
            visible={visible}
            onCancel={onClickClose}
            footer={[
                <Button key="back" onClick={onClickClose}>
                    Отмена
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => formik.handleSubmit()}
                >
                    Создать
                </Button>,
            ]}
        >
            <div className={styles.form}>
                {isLoading ? (
                    <Spin className={styles.spin} />
                ) : (
                    <>
                        <Field
                            type="text"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            placeholder="Заголовок"
                            error={!!formik.errors.title}
                        />

                        <Field
                            type="text"
                            name="logo_link"
                            value={formik.values.logo_link}
                            onChange={formik.handleChange}
                            placeholder="Ссылка на логотип"
                            error={!!formik.errors.logo_link}
                        />

                        <div className={styles.checkboxes}>
                            <Checkbox
                                name="is_blogger"
                                value={formik.values.is_blogger}
                                onChange={formik.handleChange}
                                label="Блогер"
                            />

                            <Checkbox
                                name="is_reliable"
                                value={formik.values.is_reliable}
                                onChange={formik.handleChange}
                                label="Надежный"
                            />

                            <Checkbox
                                name="is_contracted"
                                value={formik.values.is_contracted}
                                onChange={formik.handleChange}
                                label="Заключен контракт"
                            />
                        </div>

                        {response && (
                            <Typography.Text type="success">
                                Автор успешно создан. ID:
                                {response.id}
                            </Typography.Text>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};
export default AuthorAddModalComponent;
