import { SortOrder } from '../types';

export interface IAuthor {
    id: number
    title: string
    isBlogger: boolean
    sourcesCnt: number
}

export interface IGetAuthorsQueryParams {
    page?: number;
    limit?: number;
    sort_params?: IGetAuthorsQuerySortParams
}

export interface IGetAuthorsQuerySortParams {
    column: IGetAuthorsQuerySortColumn
    order: SortOrder
}

export enum IGetAuthorsQuerySortColumn {
    id = 'id',
    title = 'title',
}

export interface IGetAuthorsQueryResponse {
    totalCnt: number
    authors: IAuthor[]
}

export interface IAuthorApi {
    id: number;
    title: string;
    is_blogger: boolean;
    sources_cnt: number;
}

export interface IEditAuthorQueryParams {
    title: string
    isBlogger: boolean
}
