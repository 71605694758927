import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { removeCookies } from 'cookies-next';
import {
    Link, useLocation, useNavigate,
} from 'react-router-dom';
import Path from '../../routes/Path';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import styles from './styles.module.scss';

const PageNames = {
    [Path.App]: 'Админка',
    [Path.Sources]: 'Источники',
    [Path.Charts]: 'Графики',
    [Path.Authors]: 'Авторы',
    [Path.ParserManagerSources]: 'Парсер менеджер. Источники',
    [Path.ParserManagerTaskGroup]: 'Парсер менеджер. Группы задач',
    [Path.ParserManagerAccounts]: 'Парсер менеджер. Список аккаунтов',
    [Path.ParserManagerProxy]: 'Парсер менеджер. Прокси',
};

const HeaderContainer: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleLogout = async () => {
        removeCookies(AUTH_COOKIE_NAME);
        navigate(Path.Auth);
    };
    const pageName = PageNames[location.pathname] ?? 'None';

    return (
        <>
            <div className={styles.container}>
                <div className={styles.leftContent}>
                    <div className={styles.path}>
                        <Link to="/">
                            <span className={styles.pathText}>Главная</span>
                        </Link>
                        <span className={styles.pathSlash}>/</span>
                        <span className={styles.pathActiveText}>
                            {pageName}
                        </span>
                    </div>

                    <div className={styles.title}>
                        <span className={styles.titleText}>{pageName}</span>
                    </div>
                </div>
                <div className={styles.rightContent}>
                    <Button
                        shape="circle"
                        icon={<LogoutOutlined />}
                        className={styles.buttonLogout}
                        onClick={handleLogout}
                    />
                </div>
            </div>
            <div className={styles.line} />
        </>
    );
};
export default HeaderContainer;
