import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
    BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import {
    getCookie,
    removeCookies,
    setCookies,
} from 'cookies-next';
import { v4 as uuidv4 } from 'uuid';
import { ADMIN_API_URL } from '../utils/api';
import newCheckCookies from '../utils/CheckCookie';
import { AUTH_COOKIE_NAME } from '../utils/constants';

// create a new mutex
const mutex = new Mutex();
const baseQueryArgs: FetchBaseQueryArgs = {
    baseUrl: ADMIN_API_URL,
    prepareHeaders: (headers) => {
        const token = getCookie('Authorization');
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('x-request-id', uuidv4());
        return headers;
    },
    credentials: 'include',
};
const baseQuery = fetchBaseQuery(baseQueryArgs);

const baseQueryWithReauth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                // try to get a new token
                const refreshResult = await baseQuery({
                    url: `${baseQueryArgs.baseUrl}/check_token`,
                    method: 'POST',
                    headers: baseQueryArgs.headers,
                    credentials: baseQueryArgs.credentials,
                }, api, extraOptions);
                const res = refreshResult?.data as { token: string };
                if (res?.token) {
                    // store the new token
                    setCookies(AUTH_COOKIE_NAME, res.token);
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    const isRestore = window.location.pathname.indexOf('RestorePassword') + 1;
                    const isRestoreLink = window.location.pathname.indexOf('restore_password') + 1;
                    if (window.location.pathname !== '/auth' && !isRestore && !isRestoreLink) {
                        removeCookies(AUTH_COOKIE_NAME);
                        newCheckCookies(AUTH_COOKIE_NAME);
                    }
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
        // result = await baseQuery(args, api, extraOptions);
    }
    return result;
};

export const rtkQApi = createApi({
    reducerPath: 'api',
    keepUnusedDataFor: 0,
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    tagTypes: [
        'AuthorsList',
        'SourcesList',
    ],
});
