import { Tooltip } from 'antd';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { IMenuItem } from '../types';
import styles from './styles.module.scss';

interface Props {
    item: IMenuItem;
    menuExpanded: boolean;
}

const NarrowMenuItem: React.FC<Props> = (props: Props): React.ReactElement => {
    const { menuExpanded, item } = props;
    const { label, href, icon } = item;

    return (
        <Tooltip
            placement="right"
            color="blue"
            title={label}
            align={{
                offset: [5, 0],
            }}
        >
            <li className={classnames(styles.item, styles[menuExpanded ? 'expanded' : 'normal'])}>
                <NavLink
                    to={href}
                    className={({ isActive }) => (
                        classnames(styles.link, styles[menuExpanded ? 'expanded' : 'normal'], isActive ? styles.linkActive : ''))}
                >
                    <div className={styles.linkItem}>
                        {icon}
                    </div>
                    <div className={classnames(styles.linkTitle, styles[menuExpanded ? 'expanded' : 'normal'])}>
                        {
                            menuExpanded
                                ? item.label
                                : null
                        }
                    </div>
                </NavLink>
            </li>
        </Tooltip>
    );
};
export default NarrowMenuItem;
