import {
    Alert, Button, Modal, Spin,
} from 'antd';
import { FormikProps } from 'formik';
import Field from '../../../components/common/Field';

interface IProps {
    isOpen: boolean;
    handleClickClose: () => void;
    formik: FormikProps<{ frequencyParse: number }>;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
}

const TaskEditModalComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        isOpen, handleClickClose, formik,
        isLoading, isError, isSuccess,
    } = props;
    return (
        <Modal
            title="Редактирование задачи."
            visible={isOpen}
            onCancel={handleClickClose}
            footer={!isSuccess ? [
                <Button key="back" onClick={handleClickClose}>
                    Отмена
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => formik.handleSubmit()}
                >
                    Сохранить
                </Button>,
            ] : [
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleClickClose}
                >
                    Закрыть
                </Button>,
            ]}
        >
            {!isLoading && !isError && !isSuccess
                    && (
                        <>
                            <p>Частота парсинга: </p>
                            <Field
                                type="text"
                                name="frequencyParse"
                                value={formik.values.frequencyParse}
                                onChange={formik.handleChange}
                                placeholder="Частота парсинга"
                                error={!!formik.errors.frequencyParse}
                            />
                            {formik.errors.frequencyParse && (
                                <p>Минимальная частота парсинга 10 мин</p>
                            )}
                        </>
                    )}
            {isLoading && <Spin />}
            {isError && <Alert message="Произошла ошибка при попытке редактировать задачу." type="error" />}
            {isSuccess && <Alert message="Задача успешно изменена." type="success" />}
        </Modal>
    );
};
export default TaskEditModalComponent;
