import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { SortOrder } from '../../services/types';

interface Props {
    onChange: (value: SortOrder | null) => void;
    value?: SortOrder | null
}

const SortButton: React.FC<Props> = (props: Props): React.ReactElement => {
    const { onChange, value } = props;
    const [currentValue, setValue] = useState<SortOrder | null>(null);

    const handleClick = (): void => {
        let item: SortOrder | null = null;

        if (!currentValue) {
            item = SortOrder.asc;
        }

        if (currentValue === SortOrder.asc) {
            item = SortOrder.asc;
        }

        setValue(item);
        onChange(item);
    };

    useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <button type="button" onClick={handleClick} className={styles.button}>
            <CaretUpOutlined
                className={classnames(
                    styles.iconUp,
                    currentValue === SortOrder.asc ? styles.iconActive : '',
                )}
            />
            <CaretDownOutlined
                className={classnames(
                    styles.iconDown,
                    currentValue === SortOrder.asc ? styles.iconActive : '',
                )}
            />
        </button>
    );
};
export default SortButton;
