import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IAuthor,
    IGroupedSource,
    ILocation,
    ISource,
    ISourceState,
    ISourcesTotalCount,
    ISourceType,
} from './types';

const initialState: ISourceState = {
    groupedSources: null,
    groupedSourcesLoading: false,
    sourcesTotal: 0,
    editableSource: null,
    authors: null,
    locations: null,
    types: null,
    typesLoadingStatus: false,
    totalCount: null,
};

const { actions: sourceActions, reducer } = createSlice({
    name: 'source',
    initialState,
    reducers: {
        setGroupedSources: (
            state: ISourceState,
            action: PayloadAction<IGroupedSource[] | null>,
        ) => {
            state.groupedSources = action.payload;
        },
        setSourcesTotal: (
            state: ISourceState,
            action: PayloadAction<number>,
        ) => {
            state.sourcesTotal = action.payload;
        },
        clearGroupedSources: (state: ISourceState) => {
            state.groupedSources = null;
        },
        setEditableGroupedSource: (
            state: ISourceState,
            action: PayloadAction<ISource | null>,
        ) => {
            state.editableSource = action.payload;
        },
        clearEditableSource: (state: ISourceState) => {
            state.editableSource = null;
        },
        setAuthors: (
            state: ISourceState,
            action: PayloadAction<IAuthor[] | null>,
        ) => {
            state.authors = action.payload;
        },
        setLocations: (
            state: ISourceState,
            action: PayloadAction<ILocation[] | null>,
        ) => {
            state.locations = action.payload;
        },
        addAuthor: (state: ISourceState, action: PayloadAction<IAuthor>) => {
            const authors = state.authors.concat(action.payload);
            state.authors = authors;
        },
        setTypes: (
            state: ISourceState,
            action: PayloadAction<ISourceType[] | null>,
        ) => {
            state.types = action.payload;
        },
        setTypesLoadingStatus: (
            state: ISourceState,
            action: PayloadAction<boolean>,
        ) => {
            state.typesLoadingStatus = action.payload;
        },
        setGroupedSourcesLoading: (
            state: ISourceState,
            action: PayloadAction<boolean>,
        ) => {
            state.groupedSourcesLoading = action.payload;
        },
        deleteSourceFromGroup: (
            state: ISourceState,
            action: PayloadAction<ISource>,
        ) => {
            const { payload } = action;
            const arr = state.groupedSources.map((author) => {
                if (author.id === payload.author_id) {
                    const sources = author.sources.filter(
                        (source) => source.id !== payload.id,
                    );
                    return { ...author, sources };
                }

                return author;
            });

            state.groupedSources = arr;
        },
        setTotalCount: (
            state: ISourceState,
            { payload }: PayloadAction<ISourcesTotalCount>,
        ) => {
            state.totalCount = payload;
        },
    },
});

export { sourceActions };
export default reducer;
