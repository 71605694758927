import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Path from '../../routes/Path';

const MainPage = function MainPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`${Path.Sources}?page=1`);
    }, [navigate]);

    return <>This is a main page</>;
};

export default MainPage;
