import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { parserManagerRtkQService } from '../../services/ParserManager';
import ParserManagerTaskGroupComponent from './component';
import {
    IGetTaskGroupOrderColumns, IGetTaskGroupQueryParams, IGetTaskGroupSortSchema, IParserManagerTaskGroupModel,
} from '../../services/ParserManager/types';
import { ITableColumn } from '../../components/TempTable/types';
import { SortOrder } from '../../services/types';
import { IParserManagerTaskGroupFilter } from './Filter/types';
import ParserManagerTaskGroupListModal from './ListModal';
import styles from './styles.module.scss';

const limit = 10;

const ParserManagerTaskGroupPage : React.FC = (): React.ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: taskTypes, isFetching: isTaskTypesFetching, isError: isTaskTypesError } = parserManagerRtkQService.useGetTaskTypesQuery();
    const [getTaskGroup, { data: taskGroup, isFetching: isTaskGroupFetching, isError: isTaskGroupError }] = parserManagerRtkQService.useLazyGetTaskGroupQuery();
    const { data: statuses, isFetching: isStatusesFetching, isError: isStatusesError } = parserManagerRtkQService.useGetStatusesQuery();

    const navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = useState<number>(() => Number(searchParams.get('page')));
    const [filterSort, setFilterSort] = useState<IGetTaskGroupSortSchema>(null);
    const [filter, setFilter] = useState<IParserManagerTaskGroupFilter>({});

    useEffect(() => {
        const pageValue = Number(searchParams.get('page'));
        if (pageValue === 0) {
            navigate(`${location.pathname}?page=1`);
        }
        setPage(pageValue);
    }, [searchParams, navigate, location]);

    const args = useMemo(() => {
        const params: IGetTaskGroupQueryParams = {};

        if (filterSort) {
            params.order_by = filterSort.column;
            params.order_direction = filterSort.order;
        }
        if (filter && Number.isInteger(filter.statusId)) params.status_id = filter.statusId;
        if (filter && Number.isInteger(filter.taskTypeId)) params.task_type_id = filter.taskTypeId;

        return params;
    }, [filterSort, filter]);

    useEffect(() => {
        if (page) {
            getTaskGroup({
                ...args,
                page: Number(page),
                limit,
            });
        }
    }, [page, getTaskGroup, args]);

    const isFetching = useMemo(
        () => isTaskGroupFetching || isTaskTypesFetching || isStatusesFetching,
        [isTaskGroupFetching, isTaskTypesFetching, isStatusesFetching],
    );

    const isError = useMemo(
        () => isTaskGroupError || isTaskTypesError || isStatusesError,
        [isTaskGroupError, isTaskTypesError, isStatusesError],
    );

    const onChangePage = (nextPage: number): void => {
        setSearchParams({ page: String(nextPage) });
    };

    const handleClickSort = (
        column: ITableColumn<IParserManagerTaskGroupModel>,
        order: SortOrder,
    ): void => {
        setFilterSort({
            column: IGetTaskGroupOrderColumns[column.dataIndex],
            order,
        });
    };

    const getTaskTypeDescription = (id: number): string => {
        const task = taskTypes.filter((item) => item.id === id);
        return task[0] ? task[0].description : 'Не определено';
    };

    const getStatusDescription = (id: number): string => {
        const status = statuses.filter((item) => item.id === id);
        return status[0] ? status[0].name : 'Не определено';
    };

    const onChangeFilter = (data: IParserManagerTaskGroupFilter): void => {
        setFilter(data);
    };

    const columns: ITableColumn<IParserManagerTaskGroupModel>[] = [
        {
            id: 1,
            dataIndex: 'id',
            title: 'ID',
            sortable: true,
        },
        {
            id: 2,
            dataIndex: 'statusId',
            title: 'Статус',
            render: (value: number) => (
                <span>{getStatusDescription(value)}</span>
            ),
        },
        {
            id: 3,
            title: 'Заверешно',
            dataIndex: 'finishedAt',
            sortable: true,
            render: (dateString: string) => (
                <span>{new Date(dateString).toLocaleString()}</span>
            ),
        },
        {
            id: 4,
            title: 'Создано',
            dataIndex: 'createAt',
            sortable: true,
            render: (dateString: string) => (
                <span>{new Date(dateString).toLocaleString()}</span>
            ),
        },
        {
            id: 5,
            title: 'Тип задачи',
            dataIndex: 'taskTypeId',
            render: (value: number) => (
                <span className={styles.textWrap}>{getTaskTypeDescription(value)}</span>
            ),
        },
        {
            id: 6,
            title: '',
            dataIndex: 'id',
            render: (_value, record) => (
                <ParserManagerTaskGroupListModal getStatusDescription={getStatusDescription} data={record} />
            ),
        },
    ];

    return (
        <ParserManagerTaskGroupComponent
            isFetching={isFetching}
            taskGroup={taskGroup?.data}
            page={page}
            limit={limit}
            onChangePage={onChangePage}
            taskGroupTotal={taskGroup?.total ?? 0}
            handleClickSort={handleClickSort}
            onChangeFilter={onChangeFilter}
            isError={isError}
            columns={columns}
        />
    );
};
export default ParserManagerTaskGroupPage;
