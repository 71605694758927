import { DownOutlined } from '@ant-design/icons';
import {
    Alert, Button, Modal,
} from 'antd';
import { useMemo, useState } from 'react';
import { parserManagerRtkQService } from '../../../services/ParserManager';
import { IParserManagerSourceModel, IParserManagerTaskGroupModel, IParserManagerTaskModel } from '../../../services/ParserManager/types';
import styles from '../styles.module.scss';
import TempTable from '../../../components/TempTable';
import { ITableColumn } from '../../../components/TempTable/types';

interface IProps {
    data: IParserManagerTaskGroupModel;
    getStatusDescription: (id: number) => string;
}

const ParserManagerTaskGroupListModal: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { data, getStatusDescription } = props;

    const [getTaskGroup, { data: taskGroup, isFetching, isError }] = parserManagerRtkQService.useLazyGetTaskGroupByIdQuery();
    const [isVisible, setVisible] = useState<boolean>(false);

    const columns: ITableColumn<IParserManagerTaskModel>[] = useMemo(() => [
        {
            id: 1,
            title: 'ID',
            dataIndex: 'id',
        },
        {
            id: 2,
            title: 'Статус',
            dataIndex: 'statusId',
            render: (value: number) => (
                <span>{getStatusDescription(value)}</span>
            ),
        },
        {
            id: 3,
            title: 'Заверешно',
            dataIndex: 'finishedAt',
            render: (dateString: string) => (
                <span>{new Date(dateString).toLocaleString()}</span>
            ),
        },
        {
            id: 4,
            title: 'Истекает',
            dataIndex: 'expiredAt',
            render: (dateString: string) => (
                <span>{new Date(dateString).toLocaleString()}</span>
            ),
        },
        {
            id: 5,
            title: 'ID поста',
            dataIndex: 'postId',
        },
        {
            id: 6,
            title: 'Источник',
            dataIndex: 'source',
            render: (source: IParserManagerSourceModel, record) => (
                <span style={{
                    width: 300, display: 'flex', flexFlow: 'column nowrap',
                }}
                >
                    <span>{`ID: ${source.id}`}</span>
                    <span>{`Заголовок: ${source.title}`}</span>
                    <a href={source.link} target="_blank" rel="noreferrer">{source.link}</a>
                    <span>{record.additionalData}</span>
                </span>
            ),
        },
    ], [getStatusDescription]);

    const handleClickExpand = (): void => {
        if (!isVisible && !taskGroup) {
            getTaskGroup({
                id: data.id,
            });
        }
        setVisible((prev) => !prev);
    };

    return (
        <>
            <Button
                onClick={handleClickExpand}
                type="primary"
                shape="circle"
                icon={<DownOutlined />}
            />
            <Modal
                title="Список"
                visible={isVisible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Закрыть
                    </Button>,
                ]}
                className={styles.listModal}
            >
                <TempTable
                    columns={columns}
                    rows={taskGroup?.tasks}
                    isLoading={isFetching}
                />

                {!isFetching && isError && isVisible && <Alert type="error" message="Произошла ошибка при получении данных с сервера" />}
                {!isFetching && taskGroup?.tasks?.length < 1 && !isError && (
                    <div className={styles.notFounded}>Нет записей</div>
                )}
            </Modal>
        </>
    );
};
export default ParserManagerTaskGroupListModal;
