import HeaderContainer from '../HeaderContainer';
import NavBar from './NavBar';
import styles from './styles.module.scss';

const Layout = ({ children }: { children: React.ReactNode }) => (
    <div className={styles.wrapper}>
        <NavBar />

        <div className={styles.content}>
            <HeaderContainer />
            {children}
        </div>
    </div>
);

export default Layout;
