import {
    Button,
    Checkbox,
    DatePicker,
    Drawer,
    Radio,
    RadioChangeEvent,
    Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import LocationSelect from '../../../components/LocationSelect';
import { ISourceFilterSchema } from '../../../services/Source/types';
import styles from './styles.module.scss';
import { FilterBooleanOptions, ISourceFilterData } from './types';
import { sourceManagerRtkQService } from '../../../services/Source';

interface IProps {
    onChange: (data: ISourceFilterData) => void;
}

const booleanOptions = [
    { value: FilterBooleanOptions.notUse, label: 'Не использовать' },
    { value: FilterBooleanOptions.disabled, label: 'Нет' },
    { value: FilterBooleanOptions.enabled, label: 'Да' },
];

const SourceFilter: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { onChange } = props;
    const [isVisible, setVisible] = useState(false);
    const [location, setLocation] = useState<number[]>([]);
    const [enabled, setEnabled] = useState<FilterBooleanOptions>(
        FilterBooleanOptions.notUse,
    );
    const [allowRussia, setAllowRussia] = useState<FilterBooleanOptions>(
        FilterBooleanOptions.notUse,
    );
    const [autoDiscover, setAutoDiscover] = useState<FilterBooleanOptions>(
        FilterBooleanOptions.notUse,
    );
    const [sourceType, setSourceType] = useState<number[] | null>(null);
    const [parsedDate, setParsedDate] = useState<Moment | null>(null);
    const [createdDate, setCreatedDate] = useState<Moment | null>(null);
    const [inactiveDate, setInactiveDate] = useState<Moment | null>(null);

    const { data: platformsData } = sourceManagerRtkQService.useGetSourceTypesQuery();

    const showDrawer = (): void => setVisible(true);

    const closeDrawer = (): void => setVisible(false);

    const handleClickConfirm = (): void => {
        const args: ISourceFilterSchema = {
            location,
        };

        if (enabled !== FilterBooleanOptions.notUse) args.enabled = !!enabled;
        if (allowRussia !== FilterBooleanOptions.notUse) args.allow_in_russia = !!allowRussia;
        if (autoDiscover !== FilterBooleanOptions.notUse) args.auto_discover = !!autoDiscover;
        if (sourceType) args.source_type = sourceType;
        if (parsedDate) args.parsed = parsedDate.toISOString();
        if (createdDate) args.created = createdDate.toISOString();

        onChange({
            filterParams: args,
            inactiveDate,
        });
        closeDrawer();
    };

    const clearFilter = (): void => {
        setEnabled(FilterBooleanOptions.notUse);
        setAllowRussia(FilterBooleanOptions.notUse);
        setAutoDiscover(FilterBooleanOptions.notUse);
        setSourceType(null);
        setParsedDate(null);
        setCreatedDate(null);
    };

    const handleClickClear = (): void => {
        closeDrawer();
        clearFilter();
        onChange(null);
    };

    const onChangeEnabled = (e: RadioChangeEvent): void => setEnabled(e.target.value);

    const onChangeLocation = (value: number[]) => setLocation(value);

    const onChangeAllowRussia = (e: RadioChangeEvent): void => setAllowRussia(e.target.value);

    const onChangeAutoDiscover = (e: RadioChangeEvent): void => setAutoDiscover(e.target.value);

    const onChangeParsedDate = (date: Moment | null): void => setParsedDate(date);

    const onChangeCreatedDate = (date: Moment | null): void => setCreatedDate(date);

    const onChangeInactiveDate = (date: Moment | null): void => setInactiveDate(date);

    const onChangeSourceType = (checkedValues: number[]): void => {
        setSourceType(checkedValues);
    };

    const platforms = useMemo(() => {
        if (!platformsData) return [];
        return platformsData.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [platformsData]);

    return (
        <>
            <Button type="primary" onClick={showDrawer} style={{ width: 100 }}>
                Фильтры
            </Button>
            <Drawer
                title="Фильтры"
                placement="left"
                onClose={closeDrawer}
                visible={isVisible}
            >
                <div className={styles.container}>
                    <LocationSelect
                        value={location}
                        mode="multiple"
                        onChange={onChangeLocation}
                    />

                    <div className={styles.block}>
                        <Typography.Text>Включено</Typography.Text>
                        <Radio.Group onChange={onChangeEnabled} value={enabled}>
                            {booleanOptions.map((item) => (
                                <Radio.Button
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Доступно в РФ</Typography.Text>
                        <Radio.Group
                            onChange={onChangeAllowRussia}
                            value={allowRussia}
                        >
                            {booleanOptions.map((item) => (
                                <Radio.Button
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Авто обнаружение</Typography.Text>
                        <Radio.Group
                            onChange={onChangeAutoDiscover}
                            value={autoDiscover}
                        >
                            {booleanOptions.map((item) => (
                                <Radio.Button
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Тип источника</Typography.Text>
                        <Checkbox.Group
                            options={platforms}
                            defaultValue={[]}
                            onChange={onChangeSourceType}
                        />
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Parsed Date</Typography.Text>
                        <DatePicker
                            placeholder="Выберите дату"
                            onChange={onChangeParsedDate}
                            defaultValue={parsedDate}
                        />
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Дата создания</Typography.Text>
                        <DatePicker
                            placeholder="Выберите дату"
                            onChange={onChangeCreatedDate}
                            defaultValue={createdDate}
                        />
                    </div>
                    <div className={styles.block}>
                        <Typography.Text>Неактивные источники</Typography.Text>
                        <DatePicker
                            placeholder="Выберите дату"
                            onChange={onChangeInactiveDate}
                            defaultValue={inactiveDate}
                            disabledDate={(current) => current.isAfter(moment())}
                        />
                    </div>
                    <div className={styles.actions}>
                        <Button onClick={handleClickClear}>Очистить</Button>
                        <Button type="primary" onClick={handleClickConfirm}>
                            Применить
                        </Button>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default SourceFilter;
