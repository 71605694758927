import {
    Button, Modal, Spin, Typography,
} from 'antd';
import { FormikProps } from 'formik';
import Checkbox from '../../../components/common/Checkbox';
import Field from '../../../components/common/Field';
import { IEditAuthorQueryParams } from '../../../services/Authors/types';
import styles from './styles.module.scss';

interface Props {
    formik: FormikProps<IEditAuthorQueryParams>;
    handleClickClose: () => void
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
}

const AuthorEditModalComponent: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        formik, handleClickClose, isLoading, isSuccess, isError,
    } = props;
    return (
        <Modal
            title="Редактирование автора"
            visible
            onCancel={handleClickClose}
            footer={[
                <Button key="back" onClick={handleClickClose}>
                    Отмена
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => formik.handleSubmit()}
                >
                    Подтвердить
                </Button>,
            ]}
        >

            <div className={styles.form}>
                {isLoading
                    ? (
                        <div className={styles.spin}>
                            <Spin />
                        </div>
                    )
                    : (
                        <>

                            <Field
                                type="text"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                placeholder="Заголовок"
                                error={!!formik.errors.title}
                            />

                            <Checkbox
                                name="isBlogger"
                                value={formik.values.isBlogger}
                                onChange={formik.handleChange}
                                label="Блогер"
                            />

                            {isSuccess && (
                                <Typography.Text type="success">
                                    Автор успешно обновлен.
                                </Typography.Text>
                            )}
                            {isError && (
                                <Typography.Text type="danger">
                                    Произошла ошибка при обновлении автора.
                                </Typography.Text>
                            )}
                        </>
                    )}
            </div>

        </Modal>
    );
};
export default AuthorEditModalComponent;
