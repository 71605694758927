/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useRef } from 'react';
import useOnViewport from './useOnViewport';
import styles from './styles.module.scss';

interface IPropsLastItem {
    onLastItem?: () => void
}

const LastItem: React.FC<IPropsLastItem> = ({
    onLastItem,
}): JSX.Element => {
    const lastItemRef = useRef(null);
    const onView = useOnViewport(lastItemRef);

    useEffect(() => {
        if (onView) onLastItem();
    }, [onView]);
    return (
        <div ref={lastItemRef} className={styles.root_lastItem} />

    );
};
export default React.memo(LastItem);
