import { Pagination } from 'antd';
import {
    ISourceSearchColumn,
    SourceSortColumn,
} from '../../services/Source/types';
import {
    IGroupedSource,
    ISource,
    ISourcesTotalCount,
} from '../../store/source/types';
import SourceAddModal from './SourceAddModal';
import SourceEditModal from './SourceEditModal';
import SourceFilter from './SourceFilter';
import { ISourceFilterData } from './SourceFilter/types';
import SourcesTable from './SourcesTable';
import styles from './styles.module.scss';
import { SortOrder } from '../../services/types';

interface IProps {
    groupedSources: IGroupedSource[] | null;
    onChangePage: (nextPage: number) => void;
    limit: number;
    page: number;
    sourcesTotal: number;
    editableSource: ISource;
    onSearch: (column: ISourceSearchColumn, value: string) => void;
    handleClickSort: (
        column: SourceSortColumn,
        direction: SortOrder | null
    ) => void;
    onChangeFilter: (data: ISourceFilterData) => void;
    totalCount: ISourcesTotalCount;
    totalEnabledSources: number
}

const SourcesPageComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        groupedSources,
        onChangePage,
        limit,
        page,
        sourcesTotal,
        editableSource,
        onSearch,
        handleClickSort,
        onChangeFilter,
        totalCount,
        totalEnabledSources,
    } = props;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerLeftSide}>
                    <div className={styles.headerTotal}>
                        <span>Всего источников:</span>
                        <span>{sourcesTotal}</span>
                        (
                        {totalCount && Object.entries(totalCount.totals).map((element) => (
                            <span
                                key={element[0]}
                            >
                                {`${element[0]}: ${element[1]}`}

                            </span>
                        ))}
                        )
                    </div>

                    <div className={styles.headerTotal}>
                        <span>Всего включенных источников:</span>
                        <span>{totalEnabledSources}</span>
                        (
                        {totalCount && Object.entries(totalCount.enabled_totals).map((element) => (
                            <span
                                key={element[0]}
                            >
                                {`${element[0]}: ${element[1]}`}

                            </span>
                        ))}
                        )
                    </div>

                    <SourceFilter onChange={onChangeFilter} />
                </div>

                <div className={styles.headerRightSide}>
                    <SourceAddModal />
                </div>
            </div>

            <SourcesTable
                onSearch={onSearch}
                handleClickSort={handleClickSort}
            />

            {groupedSources && groupedSources.length >= 1 && (
                <Pagination
                    current={page}
                    total={sourcesTotal}
                    onChange={onChangePage}
                    pageSize={limit}
                    showSizeChanger={false}
                />
            )}

            {editableSource && <SourceEditModal source={editableSource} />}
        </div>
    );
};
export default SourcesPageComponent;
