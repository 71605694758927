// import { env } from '../env';

// const devApiUrl = process.env.REACT_APP_BACKEND_URL || 'http://dev.admin.resheniy.ru/admin-api';
// const prodApiUrl = env.REACT_APP_BACKEND_URL;
// export const IS_DEV = process.env.NODE_ENV === 'development';
// export const ADMIN_API_URL = !prodApiUrl ? devApiUrl : prodApiUrl;

import { env } from '../env';

const devUrl = process.env.REACT_APP_BACKEND_URL ?? 'https://admin.dev.resheniy.ru';
const prodUrl = env.REACT_APP_BACKEND_URL;
const devApiUrl = `${devUrl}/admin-api/api/v3`;
const prodApiUrl = `${prodUrl}/api/v3`;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const ADMIN_API_URL = IS_DEV ? devApiUrl : prodApiUrl;
export const CURRENT_STAND = env.REACT_APP_CURRENT_STAND?.toLowerCase() ?? 'development';
export const CURRENT_STAND_URL = IS_DEV ? devUrl : prodUrl;
