import { Select } from 'antd';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { convertDataToSimpleEntity } from '../../store/source/converter';
import { getTypes } from '../../store/source/thunks/get-types';
import { ISimpleEntity } from '../../store/types';
import styles from './styles.module.scss';

interface IProps {
    value?: number;
    onChange?: (sourceTypeId: number) => void;
    error?: boolean;
    hiddenIds?: number[];
    disabled?: boolean;
}

const step = 20;

const SourceTypeSelect: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        value, onChange, error, hiddenIds, disabled,
    } = props;
    const types = useAppSelector((state) => state.source.types);
    const loading = useAppSelector((state) => state.source.typesLoadingStatus);
    const dispatch = useAppDispatch();
    const [limit, setLimit] = useState<number>(20);
    const [selected, setSelected] = useState<ISimpleEntity | null>(null);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        if (!types) {
            dispatch(getTypes());
        }
    }, [dispatch, types]);

    useEffect(() => {
        if (Number.isInteger(value) && types) {
            const type = types.filter((item) => item.id === value)[0];

            setSelected({
                label: type.title,
                value: type.id,
            });
        }
    }, [value, types]);

    const options = useMemo(() => {
        if (!types) {
            return null;
        }

        if (search.length >= 1) {
            return convertDataToSimpleEntity(types);
        }

        let data = types;

        if (hiddenIds && hiddenIds.length >= 1) {
            data = types.filter((type) => !hiddenIds.includes(type.id));
        }

        return convertDataToSimpleEntity(data.slice(0, limit));
    }, [types, limit, search, hiddenIds]);

    const handleChange = (changeValue: ISimpleEntity) => {
        setSelected(changeValue);
        onChange(changeValue.value);
    };

    const handleSearch = (searchValue: string) => {
        setSearch(searchValue);
    };

    const onScroll = async (e: any) => {
        const { target } = e;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (options.length < types.length) {
                setLimit((prev) => prev + step);
            }
        }
    };

    const filterByLabel = (input: string, option: ISimpleEntity) => {
        const label = option.label.toLowerCase();
        return label.includes(input.toLowerCase());
    };

    return (
        <Select
            showSearch
            optionFilterProp="children"
            labelInValue
            placeholder="Тип источника"
            onChange={handleChange}
            onSearch={handleSearch}
            searchValue={search}
            value={selected}
            loading={loading}
            className={classnames(styles.item, error ? styles.error : '')}
            options={options}
            onPopupScroll={onScroll}
            filterOption={filterByLabel}
            disabled={disabled}
        />
    );
};
export default SourceTypeSelect;
