import {
    Alert,
    Button, List, Modal, Skeleton, Typography,
} from 'antd';
import { IParserManagerTaskModel } from '../../../services/ParserManager/types';
import styles from './styles.module.scss';

interface IProps {
    isOpen: boolean;
    handleClickClose: () => void;
    handleClickGetTasks: () => void;
    tasks: IParserManagerTaskModel[];
    sourceId: number;
    isFetching: boolean
    getStatusDescription: (id: number) => void;
    taskTypeDescription: string
    isError: boolean
}

const LastTasksModalComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        isOpen, handleClickClose, handleClickGetTasks, tasks, sourceId,
        isFetching, getStatusDescription, taskTypeDescription, isError,
    } = props;

    return (
        <>
            <Modal
                title={`Последние задачи источника: ${sourceId}. ${taskTypeDescription}`}
                visible={isOpen}
                onCancel={handleClickClose}
                footer={[
                    <Button key="close" onClick={handleClickClose}>Закрыть</Button>,
                ]}
            >
                {!isFetching && tasks && !isError && (
                    <List
                        size="small"
                        bordered
                        dataSource={tasks}
                        renderItem={(item) => (
                            <List.Item className={styles.item}>
                                <Typography.Text>
                                    ID:
                                    {' '}
                                    {item.id}
                                </Typography.Text>
                                <Typography.Text>
                                    Group id:
                                    {' '}
                                    {item.groupId}
                                </Typography.Text>
                                <Typography.Text>
                                    Status:
                                    {' '}
                                    {getStatusDescription(item.statusId)}
                                </Typography.Text>
                                <Typography.Text>
                                    Завершено:
                                    {' '}
                                    {new Date(
                                        item.finishedAt,
                                    ).toLocaleString()}
                                </Typography.Text>
                                {item.additionalData
                                && (
                                    <Typography.Text>
                                        {item.additionalData}
                                    </Typography.Text>
                                )}
                            </List.Item>
                        )}
                    />
                )}
                {isFetching && <Skeleton active />}
                {!isFetching && isError && <Alert type="error" message="Произошла ошибка при получении данных с сервера" />}
            </Modal>
            <Button onClick={handleClickGetTasks}>Последние 10 задач</Button>
        </>
    );
};
export default LastTasksModalComponent;
