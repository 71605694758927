import { message } from 'antd';
import { FormikProps, useFormik } from 'formik';
import { useState } from 'react';
import SourceService from '../../../services/Source/SourceService';
import { SourceTypeEnum } from '../../../store/source/types';
import SourceAddModalComponent from './component';
import { IValues } from './types';
import validationScheme from './validationSchema';

const SourceAddModal: React.FC = (): React.ReactElement => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState<1 | 2>(1);
    const [outSourceId, setOutSourceId] = useState<number>(0);

    const formik: FormikProps<IValues> = useFormik<IValues>({
        initialValues: {
            enabled: false,
            auto_discover: false,
            allow_in_russia: false,
        } as IValues,
        validationSchema: validationScheme,
        validateOnChange: false,

        onSubmit: async (values: IValues) => {
            setLoading(true);
            const args = {
                author_id: values.author_id,
                title: values.title,
                link: values.link,
                parse_link: values.link,
                out_source_id: outSourceId,
                location_id: values.location_id,
                tag: values.tag,
                auto_discover: values.auto_discover,
                allow_in_russia: values.allow_in_russia,
                enabled: values.enabled,
                type_id: values.type_id,
            };

            if (values.type_id === SourceTypeEnum.rss) {
                args.parse_link = values.feed_url;
                if (!values.link) args.link = values.feed_url;
            }

            try {
                const response = await SourceService.create(args);
                message.success(
                    `Источник успешно добавлен. ID: ${response.data.id}`,
                );
                formik.resetForm();
                setStep(1);
            } catch (error) {
                message.error('Некорректное значение');
            } finally {
                setLoading(false);
            }
        },
    });

    const getDataByTag = async (): Promise<void> => {
        setLoading(true);
        try {
            const response = await SourceService.getDataByTag(
                formik.values.type_id,
                formik.values.tag,
            );
            const { link, title, out_source_id: outSourceID } = response.data;
            setOutSourceId(outSourceID);

            formik.setValues((values) => ({
                ...values,
                link,
                title,
            }));

            setStep(2);
        } catch (error) {
            message.error('Некорректное значение');
        } finally {
            setLoading(false);
        }
    };
    const getDataByFeedUrl = async (): Promise<void> => {
        setLoading(true);

        try {
            await SourceService.getDataByFeedUrl(formik.values.feed_url);
            setStep(2);
        } catch (error) {
            message.error('Некорректное значение');
        } finally {
            setLoading(false);
        }
    };

    const handleClickSubmit = async (): Promise<void> => {
        if (step === 1 && formik.values.type_id === SourceTypeEnum.rss) {
            await getDataByFeedUrl();
            return;
        }
        if (step === 1) {
            await getDataByTag();
            return;
        }
        if (step === 2) {
            formik.handleSubmit();
        }
    };

    const handleClickClose = (): void => {
        setOpen(false);
    };

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClickPrevStep = (): void => {
        setStep(1);
        formik.setFieldValue('link', '');
        formik.setFieldValue('title', '');
        setOutSourceId(0);
        formik.setErrors({});
    };

    return (
        <SourceAddModalComponent
            isOpen={isOpen}
            handleClickClose={handleClickClose}
            handleClickSubmit={handleClickSubmit}
            handleClickOpen={handleClickOpen}
            formik={formik}
            isLoading={isLoading}
            step={step}
            handleClickPrevStep={handleClickPrevStep}
        />
    );
};
export default SourceAddModal;
