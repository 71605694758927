import { Alert } from 'antd';
import { IParserManagerTaskGroupModel } from '../../services/ParserManager/types';
import styles from './styles.module.scss';
import { SortOrder } from '../../services/types';
import ParserManagerTaskGroupFilter from './Filter';
import { IParserManagerTaskGroupFilter } from './Filter/types';
import { ITableColumn } from '../../components/TempTable/types';
import TempTable from '../../components/TempTable';

interface IProps {
    isFetching: boolean;
    taskGroup: IParserManagerTaskGroupModel[];
    page: number;
    limit: number;
    onChangePage: (nextPage: number) => void;
    taskGroupTotal: number;
    handleClickSort: (
        column: ITableColumn<IParserManagerTaskGroupModel>,
        order: SortOrder,
    ) => void;
    onChangeFilter: (data: IParserManagerTaskGroupFilter) => void;
    isError: boolean
    columns: ITableColumn<IParserManagerTaskGroupModel>[]
}

const ParserManagerTaskGroupComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        isFetching,
        taskGroup,
        page,
        limit,
        onChangePage,
        taskGroupTotal,
        handleClickSort,
        onChangeFilter,
        isError,
        columns,
    } = props;

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <ParserManagerTaskGroupFilter onChange={onChangeFilter} />
            </div>

            <TempTable
                columns={columns}
                rows={taskGroup ?? []}
                isLoading={isFetching}
                pagination={{
                    currentPage: page,
                    total: taskGroupTotal,
                    onChange: onChangePage,
                    limit,
                }}
                onSort={handleClickSort}
            />

            {!isFetching && taskGroup?.length < 1 && !isError && (
                <div className={styles.notFounded}>Нет записей</div>
            )}

            {!isFetching && isError && <Alert type="error" message="Произошла ошибка при получении данных с сервера" />}
        </div>
    );
};
export default ParserManagerTaskGroupComponent;
