import { FormikProps, useFormik } from 'formik';
import TaskEditModalComponent from './component';
import validationScheme from './validationSchema';
import { parserManagerRtkQService } from '../../../services/ParserManager';
import { IParserManagerSourceTaskModel } from '../../../services/ParserManager/types';

interface IProps {
    task: IParserManagerSourceTaskModel
    handleClickClose: () => void;
}

const TaskEditModal: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { task, handleClickClose } = props;

    const [editTask, { isLoading, isError, isSuccess }] = parserManagerRtkQService.usePutEditTaskMutation();

    const formik: FormikProps<{ frequencyParse: number }> = useFormik<{ frequencyParse: number }>(
        {
            initialValues: {
                frequencyParse: task?.frequencyParse ?? 0,
            },
            validationSchema: validationScheme,
            validateOnChange: false,

            onSubmit: async (values) => {
                editTask({
                    sourceId: task.sourceId,
                    taskTypeId: task.taskTypeId,
                    frequencyParse: Number(values.frequencyParse),
                });
            },
        },
    );

    return (
        <TaskEditModalComponent
            isOpen
            handleClickClose={handleClickClose}
            formik={formik}
            isLoading={isLoading}
            isError={isError}
            isSuccess={isSuccess}
        />
    );
};
export default TaskEditModal;
