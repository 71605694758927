import { useEffect, useMemo, useState } from 'react';
import PostService from '../../../services/Post/PostService';
import { ISimpleEntity } from '../../../store/types';
import ChartColors from '../../../utils/ChartJs/ChartColors';
import ChartJsDefaultOptions from '../../../utils/ChartJs/ChartJsDefaultOptions';
import NewsAggregationChartComponent from './component';
import { IDataEntity, IDataSet } from './types';
import { sourceManagerRtkQService } from '../../../services/Source';

const periods: ISimpleEntity[] = [
    { label: 'За 1 день', value: 1 },
    { label: 'За 7 дней', value: 7 },
    { label: 'За 14 дней', value: 14 },
    { label: 'За месяц', value: 30 },
];

const NewsAggregationChart: React.FC = (): React.ReactElement => {
    const [period, setPeriod] = useState<number>(periods[1].value);
    const [responseData, setResponseData] = useState<IDataEntity[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [typeId, setTypeId] = useState<number>(1);

    const { data: platformsData } = sourceManagerRtkQService.useGetSourceTypesQuery();

    const tabsOptions = useMemo(() => {
        if (!platformsData) return null;
        return platformsData.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [platformsData]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await PostService.getAggregationByCategory({
                granularity: 'DAY',
                period_num: period,
                type_id: typeId,
            });
            setResponseData(response.data.res);
            setLoading(false);
        };

        fetchData();
    }, [period, typeId]);

    const handleChangePeriod = (value: number): void => {
        setPeriod(value);
    };

    const dataSet: IDataSet = useMemo(() => {
        const arr = responseData.map((dataItem, index) => {
            const data = dataItem.series.map((item) => ({
                y: item.value,
                x: new Date(item.x).getTime(),
            }));
            return {
                type: 'line',
                data,
                borderWidth: 1,
                borderColor: ChartColors[index],
                backgroundColor: ChartColors[index],
                label: dataItem.key,
            };
        });

        return { datasets: arr };
    }, [responseData]);

    const getOptions = useMemo(
        () => ({
            ...ChartJsDefaultOptions,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
            },
        }),
        [],
    );

    const handleChangeTypeId = (value: number): void => {
        setTypeId(value);
    };

    return (
        <NewsAggregationChartComponent
            periods={periods}
            handleChangePeriod={handleChangePeriod}
            period={period}
            dataSet={dataSet}
            getOptions={getOptions}
            isLoading={isLoading}
            tabsOptions={tabsOptions}
            handleChangeTypeId={handleChangeTypeId}
            typeId={typeId}
        />
    );
};
export default NewsAggregationChart;
