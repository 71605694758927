import { FormikProps, useFormik } from 'formik';
import { useState } from 'react';
import SourceService from '../../../services/Source/SourceService';
import { ISourceEditArgs } from '../../../services/Source/types';
import { useAppDispatch } from '../../../store/hooks';
import { sourceActions } from '../../../store/source';
import { ISource } from '../../../store/source/types';
import SourceEditModalComponent from './component';
import validationScheme from './validationSchema';

interface IProps {
    source: ISource
}

const SourceEditModal: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { source } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<ISource | null>(null);

    const handleClickClose = (): void => {
        dispatch(sourceActions.clearEditableSource());
    };

    const formik: FormikProps<ISourceEditArgs> = useFormik<ISourceEditArgs>({
        initialValues: {
            title: source.title,
            enabled: source.enabled,
            auto_discover: source.auto_discover,
            allow_in_russia: source.allow_in_russia,
            author_id: source.author_id,
            location_id: source.location_id,
        } as ISourceEditArgs,
        validationSchema: validationScheme,
        validateOnChange: false,

        onSubmit: async (values: ISourceEditArgs) => {
            setLoading(true);

            const res = await SourceService.edit(source.id, values);
            dispatch(sourceActions.clearEditableSource());

            setResponse(res.data);
            setLoading(false);
        },
    });

    const handleClickSubmit = (): void => {
        formik.handleSubmit();
    };

    return (
        <SourceEditModalComponent
            handleClickClose={handleClickClose}
            handleClickSubmit={handleClickSubmit}
            source={source}
            formik={formik}
            isLoading={isLoading}
            response={response}
        />
    );
};
export default SourceEditModal;
