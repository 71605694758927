import {
    AnyAction, combineReducers, configureStore, ThunkAction, ThunkDispatch,
} from '@reduxjs/toolkit';
import { rtkQApi } from '../services';
import sourceReducer from './source';

const rootReducer = combineReducers({
    source: sourceReducer,
    [rtkQApi.reducerPath]: rtkQApi.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat([
        rtkQApi.middleware,
    ]),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<T = void> = ThunkAction<T, AppState, unknown, AnyAction>;
export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>;
