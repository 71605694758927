import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

interface IProps {
    value: boolean;
}

const IconState: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { value } = props;
    return value ? (
        <CheckOutlined className={styles.enabled} />
    ) : (
        <CloseOutlined className={styles.disabled} />
    );
};
export default IconState;
