import { Select } from 'antd';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { convertDataToSimpleEntity } from '../../store/source/converter';
import { getLocations } from '../../store/source/thunks/get-locations';
import { ISimpleEntity } from '../../store/types';
import styles from './styles.module.scss';

interface IProps {
    value?: number | number[];
    onChange: (locationId: number | number[]) => void
    error?: boolean
    mode?: 'multiple'
}

const step = 20;

const LocationSelect: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        value, onChange, error, mode,
    } = props;
    const locations = useAppSelector((state) => state.source.locations);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(20);
    const [selected, setSelected] = useState<ISimpleEntity | ISimpleEntity[] | null>(null);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        if (!locations) {
            setLoading(true);
            dispatch(getLocations());
        }

        if (locations) {
            setLoading(false);
        }
    }, [dispatch, locations]);

    useEffect(() => {
        if (Number.isInteger(value) && locations) {
            const location = locations.filter(
                (item) => item.id === value,
            )[0];

            setSelected({
                label: location.title,
                value: location.id,
            });
        }

        if (mode === 'multiple' && Array.isArray(value) && value.length < 1) {
            setSelected([]);
        }
    }, [value, locations, mode]);

    const options = useMemo(() => {
        if (!locations) {
            return null;
        }

        if (search.length >= 1) {
            return convertDataToSimpleEntity(locations);
        }

        return convertDataToSimpleEntity(locations.slice(0, limit));
    }, [locations, limit, search]);

    const handleChange = (changeValue: ISimpleEntity | ISimpleEntity[]) => {
        setSelected(changeValue);
        if (Array.isArray(changeValue)) {
            const arr = changeValue.map((item) => item.value);
            onChange(arr);
        } else {
            onChange(changeValue.value);
        }
    };

    const handleSearch = (searchValue: string) => {
        setSearch(searchValue);
    };

    const onScroll = async (e: any) => {
        const { target } = e;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (options.length < locations.length) {
                setLimit((prev) => prev + step);
            }
        }
    };

    const filterByLabel = (input: string, option: ISimpleEntity) => {
        const label = option.label.toLowerCase();
        return label.includes(input.toLowerCase());
    };

    return (
        <Select
            showSearch
            optionFilterProp="children"
            labelInValue
            placeholder="Локация"
            onChange={handleChange}
            onSearch={handleSearch}
            searchValue={search}
            value={selected}
            loading={loading}
            className={classnames(styles.item, error ? styles.error : '')}
            options={options}
            onPopupScroll={onScroll}
            filterOption={filterByLabel}
            mode={mode}
        />
    );
};
export default LocationSelect;
