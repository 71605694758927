import { Alert } from 'antd';
import { SortOrder } from '../../services/types';
import TempTable from '../../components/TempTable';
import { ITableColumn } from '../../components/TempTable/types';
import { IParserManagerAccountModel } from '../../services/ParserManager/types';
import styles from './styles.module.scss';

interface IProps {
    columns: ITableColumn<IParserManagerAccountModel>[]
    isFetching: boolean
    accounts: IParserManagerAccountModel[]
    total: number
    page: number
    onChangePage: (page: number) => void
    onSortTable: (column: ITableColumn<IParserManagerAccountModel>, order: SortOrder) => void
    isError: boolean
}

const ParserManagerAccountsComponent: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        columns, isFetching, accounts, total,
        page, onChangePage, onSortTable, isError,
    } = props;
    return (
        <div className={styles.wrapper}>
            <TempTable
                rows={accounts ?? []}
                columns={columns}
                isLoading={isFetching}
                pagination={{
                    total,
                    currentPage: page,
                    onChange: onChangePage,
                    limit: 10,
                }}
                onSort={onSortTable}
            />
            {!isFetching && accounts?.length < 1 && !isError && (
                <Alert
                    type="info"
                    message="Нет записей"
                />
            )}
            {isError && !isFetching && (
                <Alert
                    type="error"
                    message="Произошла ошибка при получении данных с сервера."
                />
            )}
        </div>
    );
};
export default ParserManagerAccountsComponent;
