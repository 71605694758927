import { SortOrder } from '../types';

export interface IGetSourcesQueryParams {
    page?: number;
    limit?: number;
    order_direction?: SortOrder;
    order_by?: IGetSourcesOrderColumns;
    search?: string;
    enabled?: boolean;
    id?: string | number;
    title?: string;
    link?: string;
    source_type_id?: number;
}

export enum IGetSourcesOrderColumns {
    title = 'title',
    id = 'id',
}

export interface IGetSourcesSortSchema {
    column: IGetSourcesOrderColumns;
    order: SortOrder;
}

export interface IParserManagerSourceModel {
    id: number;
    parseLink: string;
    tag: string;
    allowInRussia: boolean;
    link: string;
    title: string;
    outSourceId: string;
    sourceTypeId: number;
    parseParams: IParserManagerSourceTaskModel[];
}

export interface IParserManagerSourceTaskModel {
    taskTypeId: number;
    sourceId: number;
    statusId: number;
    lastParse: string;
    frequencyParse: number;
    enabled: boolean;
}

export interface IParserManagerSourceFromApi {
    id: number;
    parse_link: string;
    tag: string;
    allow_in_russia: boolean;
    link: string;
    title: string;
    out_source_id: string;
    source_type_id: number;
    parse_params: IParserManagerSourceTaskFromApi[];
}

export interface IParserManagerSourceTaskFromApi {
    task_type_id: number;
    source_id: number;
    status_id: number;
    last_parse: string;
    frequency_parse: number;
    enabled: boolean;
}

export interface IParserManagerTaskType {
    id: number;
    name: string;
    description: string;
}

export interface IParserManagerStatus {
    id: number;
    name: string;
}

export interface IGetTasksQueryParams {
    sourceId: number;
    taskTypeId: number;
    args?: {
        page?: number;
        limit?: number;
    };
}

export interface IParserManagerTaskFromApi {
    id: number;
    group_id: number;
    status_id: number;
    status_code: null | number;
    finished_at: string;
    expired_at: string;
    additional_data: null | any;
    post_id: null | any;
    post: null | any;
    source_id: number;
    source?: IParserManagerSourceFromApi;
}

export interface IParserManagerTaskModel {
    id: number;
    groupId: number;
    statusId: number;
    statusCode: null | number;
    finishedAt: string;
    expiredAt: string;
    additionalData: null | any;
    postId: null | any;
    post: null | any;
    sourceId: number;
    source: IParserManagerSourceModel
}

export interface IParserManagerTaskEditParams {
    sourceId: number
    taskTypeId: number
    frequencyParse: number
}

export interface IGetTaskGroupQueryParams {
    page?: number;
    limit?: number;
    order_direction?: SortOrder;
    order_by?: IGetTaskGroupOrderColumns;
    status_id?: number
    task_type_id?: number
}

export enum IGetTaskGroupOrderColumns {
    id = 'id',
    createAt = 'create_at',
    finishedAt = 'finished_at',
}

export interface IGetTaskGroupSortSchema {
    column: IGetTaskGroupOrderColumns;
    order: SortOrder;
}

export interface IParserManagerTaskGroupFromApi {
    id: number;
    status_id: number;
    finished_at: string;
    create_at: string;
    task_type_id: number;
}

export interface IParserManagerTaskGroupModel {
    id: number;
    statusId: number;
    finishedAt: string;
    createAt: string;
    taskTypeId: number;
}

export interface IParserManagerTaskGroupInfoFromApi {
    id: number
    status_id: number
    finished_at: string
    create_at: string
    task_type_id: string
    tasks: IParserManagerTaskFromApi[]
}

export interface IParserManagerTaskGroupInfoModel {
    id: number
    statusId: number
    finishedAt: string
    createAt: string
    taskTypeId: string
    tasks: IParserManagerTaskModel[]
}

export interface IParserManagerAccountFromApi {
    source_type_id: number,
    credentials: any;
    id: number,
    failed_counter: number,
    created_at: string,
    last_used: string,
    ban_info: string,
    banned_until: string,
    status: IParserManagerStatuses
}

export interface IParserManagerAccountModel {
    sourceTypeId: number,
    credentials: any
    id: number,
    failedCounter: number,
    createdAt: string,
    lastUsed: string,
    banInfo: string,
    bannedUntil: string,
    status: IParserManagerStatuses
}

export interface IQueryParamsGetAccountList {
    page?: number;
    order_by?: IGetAccountListOrderColumns;
    order_direction?: SortOrder;
    status?: IParserManagerStatuses;
    source_type_id?: number;
}

export enum IParserManagerStatuses {
    available = 'available',
    temporaryBan = 'temporary_ban',
    permanentBan = 'permanent_ban',
}

export enum IGetAccountListOrderColumns {
    id = 'id',
    failedCounter = 'failed_counter',
    lastUsed = 'last_used',
    bannedUntil = 'banned_until',
    createdAt = 'created_at',
}

export interface IQueryParamsGetProxy {
    page?: number
    limit?: number
    status?: IParserManagerStatuses
    proxy_type?: IParserManagerProxyType
    order_by?: IGetProxyOrderColumns;
    order_direction?: SortOrder;
}

export interface IParserManagerProxyFromApi {
    id: number;
    proxy_type: string,
    credentials: {
        host: string,
        port: number,
        username: string,
        password: string
    },
    status: IParserManagerStatuses;
    failed_counter: number
    last_used: string
    banned_until: string | null
}

export interface IParserManagerProxyModel {
    id: number;
    proxyType: string,
    credentials: {
        host: string,
        port: number,
        username: string,
        password: string
    },
    status: IParserManagerStatuses;
    failedCounter: number
    lastUsed: string
    bannedUntil: string | null
}

export enum IParserManagerProxyType {
    http = 'http',
    socks = 'socks',
}

export enum IGetProxyOrderColumns {
    id = 'id',
    failedCounter = 'failed_counter',
    lastUsed = 'last_used',
    bannedUntil = 'banned_until',
}
