import { rtkQApi } from '..';
import { ISourceType } from './types';

export const sourceManagerRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getSourceTypes: build.query<ISourceType[], void>({
            query: () => ({
                url: 'parser_manager/dictionary/platforms',
                method: 'GET',
            }),
        }),
    }),
});
