import {
    AimOutlined, AlignCenterOutlined,
    CheckCircleOutlined, DeleteOutlined, EditOutlined, EnvironmentOutlined,
} from '@ant-design/icons';
import {
    Button, message, Popconfirm, Skeleton, Tooltip,
} from 'antd';
import SortButton from '../../../components/SortButton';
import SourceService from '../../../services/Source/SourceService';
import {
    ISourceSearchColumn,
    SourceSortColumn,
} from '../../../services/Source/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { sourceActions } from '../../../store/source';
import { ISource } from '../../../store/source/types';
import IconState from './IconState';
import InputSearch from './InputSearch';
import styles from './styles.module.scss';
import { SortOrder } from '../../../services/types';

interface Props {
    onSearch: (column: ISourceSearchColumn, value: string) => void;
    handleClickSort: (
        column: SourceSortColumn,
        direction: SortOrder | null
    ) => void;
}

const SourcesTable: React.FC<Props> = (props: Props): React.ReactElement => {
    const { onSearch, handleClickSort } = props;
    const sources = useAppSelector((state) => state.source.groupedSources);
    const loading = useAppSelector(
        (state) => state.source.groupedSourcesLoading,
    );
    const dispatch = useAppDispatch();

    const handleClickEdit = (source: ISource): void => {
        dispatch(sourceActions.setEditableGroupedSource(source));
    };

    const handleClickDelete = async (source: ISource): Promise<void> => {
        try {
            const result = await SourceService.delete(source.id);
            if (result.status === 204) {
                message.success('Источник успешно удален');
                dispatch(sourceActions.deleteSourceFromGroup(source));
            }
        } catch (error) {
            message.error('Что то пошло не так');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.table}>
                <div className={styles.tableHeader}>
                    <span>
                        <p>
                            Автор
                            <SortButton
                                onChange={(value) => {
                                    handleClickSort(
                                        SourceSortColumn.AUTHOR_TITLE,
                                        value,
                                    );
                                }}
                            />
                        </p>
                        <InputSearch
                            onSubmit={(value) => onSearch(
                                ISourceSearchColumn.AUTHOR_TITLE,
                                value,
                            )}
                        />
                    </span>
                    <span>ID</span>
                    <span>
                        <p>Заголовок</p>
                        <InputSearch
                            onSubmit={(value) => onSearch(
                                ISourceSearchColumn.SOURCE_TITLE,
                                value,
                            )}
                        />
                    </span>
                    <span>
                        <p>Ссылка / Parse link</p>
                        <InputSearch
                            onSubmit={(value) => onSearch(ISourceSearchColumn.SOURCE_LINK, value)}
                        />
                    </span>
                    <span>Локация</span>
                    <span>Тег</span>
                    <span>Внешний id</span>
                    <span>
                        <Tooltip title="Включено">
                            <CheckCircleOutlined className={styles.icon} />
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Доступно в России">
                            <EnvironmentOutlined className={styles.icon} />
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Авто обнаружение">
                            <AimOutlined className={styles.icon} />
                        </Tooltip>
                    </span>
                    <span>
                        <Tooltip title="Тип">
                            <AlignCenterOutlined className={styles.icon} />
                        </Tooltip>
                    </span>
                    <span>Создано / Спарсено</span>
                    <span />
                </div>
                {sources && sources.length >= 1 && (
                    <div className={styles.tableBody}>
                        {sources.map((author) => (
                            <div key={author.id} className={styles.tableItem}>
                                <div className={styles.tableItemTitle}>
                                    <span>{author.title}</span>
                                    <span>
                                        {author.is_blogger ? 'Блогер' : 'СМИ'}
                                    </span>
                                </div>
                                <div className={styles.tableItemList}>
                                    {author.sources.map((source) => (
                                        <div
                                            key={source.id}
                                            className={
                                                styles.tableItemListSource
                                            }
                                        >
                                            <span>{source.id}</span>
                                            <span>{source.title}</span>
                                            <span>
                                                <a href={source.link}>
                                                    {source.link}
                                                </a>
                                                <br />
                                                <a href={source.parse_link}>
                                                    {source.parse_link}
                                                </a>
                                            </span>
                                            <span>{source.location}</span>
                                            <span>
                                                {source.tag ? source.tag : '-'}
                                            </span>
                                            <span>{source.out_source_id}</span>
                                            <span>
                                                <IconState
                                                    value={source.enabled}
                                                />
                                            </span>
                                            <span>
                                                <IconState
                                                    value={
                                                        source.allow_in_russia
                                                    }
                                                />
                                            </span>
                                            <span>
                                                <IconState
                                                    value={source.auto_discover}
                                                />
                                            </span>
                                            <span>{source.type}</span>
                                            <span>
                                                {new Date(
                                                    source.created,
                                                ).toLocaleString()}
                                                <br />
                                                {new Date(
                                                    source.parsed,
                                                ).toLocaleString()}
                                            </span>
                                            <span className={styles.actions}>
                                                <Tooltip title="Редактировать">
                                                    <Button
                                                        onClick={() => handleClickEdit({
                                                            ...source,
                                                            author_id: author.id,
                                                        })}
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<EditOutlined />}
                                                    />
                                                </Tooltip>

                                                <Popconfirm
                                                    title="Вы уверены что хотите удалить источник?"
                                                    onConfirm={() => handleClickDelete({
                                                        ...source,
                                                        author_id: author.id,
                                                    })}
                                                    okText="Да"
                                                    cancelText="Нет"
                                                >
                                                    <Button
                                                        type="primary"
                                                        shape="circle"
                                                        danger
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                    />
                                                </Popconfirm>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {!loading && !sources && <div>Нет записей</div>}
                {loading && <Skeleton active />}
            </div>
        </div>
    );
};
export default SourcesTable;
