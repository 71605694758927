const ChartColors = [
    '#E67E22',
    'red',
    'purple',
    '#008080',
    '#e6beff',
    '#aa6e28',
    '#808000',
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#0082c8',
    '#f58231',
    '#911eb4',
    '#0EBFA1',
    '#46f0f0',
    '#f032e6',
    '#d2f53c',
    '#fabebe',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#ffd8b1',
    '#000080',
    '#808080',
    '#ffffff',
    '#000000',
    '#003f5c',
    '#374c80',
    '#7a5195',
    '#bc5090',
    '#ef5675',
    '#ffa600',
];

export default ChartColors;
