import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
    Button, Radio, RadioChangeEvent, Space,
} from 'antd';
import ParserManagerComponent from './component';
import { parserManagerRtkQService } from '../../services/ParserManager';
import {
    IGetSourcesOrderColumns,
    IGetSourcesQueryParams,
    IGetSourcesSortSchema,
    IParserManagerSourceModel,
    IParserManagerSourceTaskModel,
} from '../../services/ParserManager/types';
import { SortOrder } from '../../services/types';
import { ITableColumn } from '../../components/TempTable/types';
import styles from './styles.module.scss';
import IconState from '../sources/SourcesTable/IconState';
import LastTasksModal from './LastTasksModal';
import { sourceManagerRtkQService } from '../../services/Source';

const limit = 10;

const ParserManagerSourcesPage: React.FC = (): React.ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [getSources, { data: sourcesData, isFetching: isSourcesFetching, isError: isSourcesError }] = parserManagerRtkQService.useLazyGetSourcesQuery();
    const { data: taskTypes, isFetching: isTaskTypesFetching, isError: isTaskTypesError } = parserManagerRtkQService.useGetTaskTypesQuery();
    const { data: statuses, isFetching: isStatusesFetching, isError: isStatusesError } = parserManagerRtkQService.useGetStatusesQuery();
    const navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = useState<number>(() => Number(searchParams.get('page')));
    const [filterSort, setFilterSort] = useState<IGetSourcesSortSchema>(null);
    const [titleSearch, setTitleSearch] = useState<string>('');
    const [idSearch, setIdSearch] = useState<string>('');
    const [linkSearch, setLinkSearch] = useState<string>('');
    const [filterEnabled, setFilterEnabled] = useState<boolean | null>(null);
    const [editableTask, setEditableTask] = useState<IParserManagerSourceTaskModel | null>(null);
    const [filterSourceType, setFilterSourceType] = useState<number | null>(null);
    const { data: sourceTypes } = sourceManagerRtkQService.useGetSourceTypesQuery();

    useEffect(() => {
        const pageValue = Number(searchParams.get('page'));
        if (pageValue === 0) {
            navigate(`${location.pathname}?page=1`);
        }
        setPage(pageValue);
    }, [searchParams, navigate, location]);

    const args = useMemo(() => {
        const params: IGetSourcesQueryParams = {};

        if (filterSort) {
            params.order_by = filterSort.column;
            params.order_direction = filterSort.order;
        }
        if (titleSearch) params.title = titleSearch;
        if (filterEnabled !== null) params.enabled = filterEnabled;
        if (idSearch) params.id = idSearch;
        if (linkSearch) params.link = linkSearch;
        if (filterSourceType) params.source_type_id = filterSourceType;

        return params;
    }, [filterSort, titleSearch, filterEnabled, idSearch, linkSearch, filterSourceType]);

    useEffect(() => {
        if (page) {
            getSources({
                ...args,
                page: Number(page),
                limit,
            });
        }
    }, [page, getSources, args]);

    const onChangePage = (nextPage: number): void => {
        setSearchParams({ page: String(nextPage) });
    };

    const isFetching = useMemo(
        () => isSourcesFetching || isTaskTypesFetching || isStatusesFetching,
        [isSourcesFetching, isTaskTypesFetching, isStatusesFetching],
    );

    const isError = useMemo(
        () => isSourcesError || isTaskTypesError || isStatusesError,
        [isSourcesError, isTaskTypesError, isStatusesError],
    );

    const onSortTable = (
        column: ITableColumn<IParserManagerSourceModel>,
        order: SortOrder,
    ): void => {
        setFilterSort({
            column: IGetSourcesOrderColumns[column.dataIndex],
            order,
        });
    };

    const onSearchTable = (
        column: ITableColumn<IParserManagerSourceModel>,
        value: string,
    ): void => {
        if (column.dataIndex === 'title') setTitleSearch(value);
        if (column.dataIndex === 'id') setIdSearch(value);
        if (column.dataIndex === 'link') setLinkSearch(value);
    };

    const handleClickEditTask = (task: IParserManagerSourceTaskModel | null) => {
        setEditableTask(task);
    };

    const getTaskTypeDescription = (id: number): string => {
        const task = taskTypes.filter((item) => item.id === id);
        return task[0] ? task[0].description : 'Не определено';
    };

    const getStatusDescription = (id: number): string => {
        const status = statuses.filter((item) => item.id === id);
        return status[0] ? status[0].name : 'Не определено';
    };

    const handleChangeEnabled = (e: RadioChangeEvent): void => {
        setFilterEnabled(e.target.value);
    };

    const handleChangeSourceType = (e: RadioChangeEvent): void => {
        setFilterSourceType(e.target.value);
    };

    const columns: ITableColumn<IParserManagerSourceModel>[] = useMemo(() => [
        {
            id: 1,
            dataIndex: 'id',
            title: 'ID',
            width: 100,
            sortable: true,
            searchable: true,
        },
        {
            id: 2,
            dataIndex: 'title',
            title: 'Заголовок',
            width: 150,
            sortable: true,
            maxLengthForTooltipe: 20,
            searchable: true,
        },
        {
            id: 3,
            dataIndex: 'parseLink',
            title: 'Parse link',
            width: 150,
            render: (value) => (
                <a
                    href={value}
                    target="_blank"
                    className={styles.textWrap}
                    rel="noreferrer"
                >
                    {value}
                </a>
            ),
        },
        {
            id: 4,
            dataIndex: 'tag',
            title: 'Тег',
            width: 150,
        },
        {
            id: 5,
            dataIndex: 'link',
            title: 'Ссылка',
            width: 150,
            searchable: true,
            render: (value) => (
                <a
                    href={value}
                    target="_blank"
                    className={styles.textWrap}
                    rel="noreferrer"
                >
                    {value}
                </a>
            ),
        },
        {
            id: 6,
            dataIndex: 'outSourceId',
            title: 'Внешний ID',
            width: 120,
            maxLengthForTooltipe: 20,
        },
        {
            id: 7,
            dataIndex: 'allowInRussia',
            title: 'РФ',
            render: (value) => (
                <div className={styles.tableItemIcon}>
                    <IconState value={value} />
                </div>
            ),
            width: 50,
        },
        {
            id: 8,
            dataIndex: 'sourceTypeId',
            title: 'Тип источника',
            width: 150,
            render: (value: number) => (
                <span>{sourceTypes.filter((item) => item.id === value)[0].name}</span>
            ),
            renderFilter: () => (
                <Radio.Group onChange={handleChangeSourceType} value={filterSourceType}>
                    <Space direction="vertical">
                        <Radio value={null}>Не учитывать</Radio>
                        {sourceTypes && sourceTypes.map((item) => <Radio key={item.id} value={item.id}>{item.name}</Radio>)}
                    </Space>
                </Radio.Group>
            ),
        },
        {
            id: 9,
            dataIndex: 'parseParams',
            title: 'Задачи',
            width: 400,
            renderFilter: () => (
                <div className={styles.filterStatus}>
                    <Radio.Group onChange={handleChangeEnabled} value={filterEnabled}>
                        <Space direction="vertical">
                            <Radio value={null}>Не учитывать</Radio>
                            <Radio value={false}>Отключено</Radio>
                            <Radio value>Включено</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            ),
            render: (value, record) => (
                <span className={styles.tableItemTasks}>
                    {value.map((task) => (
                        <div key={task.frequencyParse}>
                            <p>
                                Тип задачи:
                                {' '}
                                {getTaskTypeDescription(task.taskTypeId)}
                            </p>
                            <p>
                                Статус последнего парсинга:
                                {' '}
                                {getStatusDescription(task.statusId)}
                            </p>
                            <p>
                                Последний успешный парсинг:
                                {' '}
                                {new Date(task.lastParse).toLocaleString()}
                            </p>
                            <p>
                                Частота:
                                {' '}
                                {task.frequencyParse}
                                {' '}
                                (мин)
                            </p>
                            <p>
                                {task.enabled ? 'Включено' : 'Отключено'}
                            </p>
                            <div className={styles.tableItemTasksButtons}>
                                <LastTasksModal
                                    sourceId={record.id}
                                    taskTypeId={task.taskTypeId}
                                />
                                <Button
                                    onClick={() => handleClickEditTask(task)}
                                >
                                    Редактировать
                                </Button>
                            </div>
                        </div>
                    ))}
                </span>
            ),
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [filterSourceType, filterEnabled, sourceTypes]);

    return (
        <ParserManagerComponent
            sources={sourcesData?.data}
            isFetching={isFetching}
            page={page}
            limit={limit}
            onChangePage={onChangePage}
            onSortTable={onSortTable}
            onSearchTable={onSearchTable}
            sourcesTotal={sourcesData?.total ?? 0}
            handleClickEditTask={handleClickEditTask}
            editableTask={editableTask}
            isError={isError}
            columns={columns}
        />
    );
};
export default ParserManagerSourcesPage;
