import { Input } from 'antd';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
    name: string;
    type?: string;
    value: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
}

const Field: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        name,
        type,
        value,
        onChange,
        error,
        placeholder,
        readOnly,
        disabled,
    } = props;

    return (
        <Input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            className={classnames(styles.input, error ? styles.error : '')}
            disabled={disabled}
        />
    );
};
export default Field;
