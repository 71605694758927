import axios, { AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getCookie } from 'cookies-next';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { ADMIN_API_URL } from '../utils/api';
import refreshAuthLogic from './refreshAuthLogic';

const $api = axios.create({
    baseURL: ADMIN_API_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Request-ID': uuidv4(),
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
});

$api.interceptors.request.use((config: AxiosRequestConfig) => {
    const accessToken = getCookie('Authorization');

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});

createAuthRefreshInterceptor($api, refreshAuthLogic);

export default $api;
