import { PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { convertDataToSimpleEntity } from '../../store/source/converter';
import { getAuthors } from '../../store/source/thunks/get-authors';
import { ISimpleEntity } from '../../store/types';
import AuthorAddModal from '../AuthorAddModal';
import styles from './styles.module.scss';

interface IProps {
    value?: number;
    onChange?: (authorId: number) => void;
    error?: boolean;
}

const step = 20;

const AuthorSelect: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { value, onChange, error } = props;
    const authors = useAppSelector((state) => state.source.authors);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(20);
    const [selected, setSelected] = useState<ISimpleEntity | null>(null);
    const [search, setSearch] = useState<string>('');
    const [isOpenAuthorAddModal, setOpenAuthorAddModal] = useState<boolean>(false);

    useEffect(() => {
        if (!authors) {
            setLoading(true);
            dispatch(getAuthors());
        }

        if (authors) {
            setLoading(false);
        }
    }, [dispatch, authors]);

    useEffect(() => {
        if (value && authors) {
            const author = authors.filter((item) => item.id === value)[0];
            setSelected({
                label: author.title,
                value: author.id,
            });
        }
    }, [value, authors]);

    const options = useMemo(() => {
        if (!authors) {
            return null;
        }

        if (search.length >= 1) {
            return convertDataToSimpleEntity(authors);
        }

        return convertDataToSimpleEntity(authors.slice(0, limit));
    }, [authors, limit, search]);

    const handleChange = (changeValue: ISimpleEntity) => {
        setSelected(changeValue);
        onChange(changeValue.value);
    };

    const handleSearch = (searchValue: string) => {
        setSearch(searchValue);
    };

    const onScroll = async (e: any) => {
        const { target } = e;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (options.length < authors.length) {
                setLimit((prev) => prev + step);
            }
        }
    };

    const filterByLabel = (input: string, option: ISimpleEntity) => {
        const label = option.label.toLowerCase();
        return label.includes(input.toLowerCase());
    };

    const handleClickAuthorAdd = () => {
        setOpenAuthorAddModal(true);
    };

    const handleClickCloseAuthorAddModal = () => {
        setOpenAuthorAddModal(false);
    };

    return (
        <>
            <Select
                showSearch
                optionFilterProp="children"
                labelInValue
                placeholder="Автор"
                onChange={handleChange}
                onSearch={handleSearch}
                searchValue={search}
                value={selected}
                loading={loading}
                options={options}
                onPopupScroll={onScroll}
                filterOption={filterByLabel}
                className={classnames(styles.item, error ? styles.error : '')}
                // eslint-disable-next-line react/no-unstable-nested-components
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <div className={styles.footer}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={handleClickAuthorAdd}
                            >
                                Добавить автора
                            </Button>
                        </div>
                    </>
                )}
            />

            <AuthorAddModal
                visible={isOpenAuthorAddModal}
                onClose={handleClickCloseAuthorAddModal}
            />
        </>
    );
};
export default AuthorSelect;
