const COLOR_PALETE = [
    '#5b8ff9',
    '#5ad8a6',
    '#5e7092',
    '#f6bd18',
    '#6f5efa',
    '#6ec8ec',
    '#945fb9',
    '#ff9845',
    '#299796',
    '#fe99c3',
    '#47ca8d',
    '#af8f72',
    '#691010',
    '#2e22d6',
    '#d17878',
    '#cd5af0',
];

export default {
    COLOR_PALETE,
};

export const AUTH_COOKIE_NAME = 'Authorization';
