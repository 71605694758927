import {
    Radio, Select, Spin, Typography,
} from 'antd';
import { Line } from 'react-chartjs-2';
import { ISimpleEntity } from '../../../store/types';
import styles from './styles.module.scss';
import { IDataSet } from './types';

interface Props {
    periods: ISimpleEntity[];
    handleChangePeriod: (value: number) => void;
    period: number;
    dataSet: IDataSet;
    getOptions: any;
    isLoading: boolean;
    tabsOptions: {
        label: string;
        value: number;
    }[];
    handleChangeTypeId: (value: number) => void;
    typeId: number;
}

const NewsAggregationChartComponent: React.FC<Props> = (
    props: Props,
): React.ReactElement => {
    const {
        periods,
        handleChangePeriod,
        period,
        dataSet,
        getOptions,
        isLoading,
        tabsOptions,
        handleChangeTypeId,
        typeId,
    } = props;

    return (
        <div className={styles.wrapper}>
            <Typography.Title level={3}>Новости по категориям</Typography.Title>

            <div className={styles.header}>
                <Select
                    value={period}
                    onChange={handleChangePeriod}
                    className={styles.select}
                >
                    {periods.map((option) => (
                        <Select.Option
                            key={Math.random() * Date.now()}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>

                <div className={styles.types}>
                    <Radio.Group
                        onChange={(e) => handleChangeTypeId(e.target.value)}
                        value={typeId}
                        options={tabsOptions}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>
            </div>

            <div className={styles.chartContainer}>
                <div className={styles.chart}>
                    <Line options={getOptions} data={dataSet as any} />
                </div>

                {isLoading && (
                    <div className={styles.chartLoader}>
                        <Spin size="large" />
                    </div>
                )}
            </div>
        </div>
    );
};
export default NewsAggregationChartComponent;
