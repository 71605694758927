import { Checkbox, Select, Spin } from 'antd';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import PostService from '../../services/Post/PostService';
import { GranularityEnum, ISimpleEntity } from '../../store/types';
import ChartColors from '../../utils/ChartJs/ChartColors';
import ChartJsDefaultOptions from '../../utils/ChartJs/ChartJsDefaultOptions';
import styles from './styles.module.scss';
import { sourceManagerRtkQService } from '../../services/Source';

ChartJS.register(
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
);

const periods: ISimpleEntity[] = [
    { label: 'За 7 дней', value: 7 },
    { label: 'За 14 дней', value: 14 },
    { label: 'За месяц', value: 30 },
];

const CommentsAmountChart: React.FC = (): React.ReactElement => {
    const [data, setData] = useState<{ x: any; y: number }[][]>([]);
    const [period, setPeriod] = useState<number>(7);
    const [displayedPlatforms, setDisplayedPlatforms] = useState<number[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const { data: platformsData } = sourceManagerRtkQService.useGetSourceTypesQuery();

    const platforms = useMemo(() => {
        if (!platformsData) return [];
        return platformsData.map((item) => ({
            type: item.id,
            label: item.name,
        }));
    }, [platformsData]);

    useEffect(() => {
        if (platformsData) {
            const ids = platformsData.map((item) => item.id);
            setDisplayedPlatforms(ids);
        }
    }, [platformsData]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await PostService.countCommentsByPlatform({
                period_num: period,
                granularity: GranularityEnum.day,
                date_column: 'parsed',
            });

            const arr = response.data.reduce(
                (acc, item) => {
                    const newAcc = acc;
                    const x = item.dt;
                    platformsData.forEach((platform) => {
                        newAcc[platform.id - 1].push({
                            x,
                            y: item[platform.name],
                        });
                    });
                    return newAcc;
                },
                [[], [], [], [], [], [], []],
            );

            setData(arr);
            setLoading(false);
        };

        if (platformsData) fetchData();
    }, [period, platformsData]);

    const dataSet = useMemo(() => {
        const arr = platforms.map((item, index) => {
            const isDisplayed = displayedPlatforms.filter(
                (displayed) => displayed === item.type,
            )[0];
            return {
                type: 'line',
                data: isDisplayed ? data[item.type - 1] : [],
                borderWidth: 1,
                borderColor: ChartColors[index],
                backgroundColor: ChartColors[index],
            };
        });

        return { datasets: arr };
    }, [displayedPlatforms, data, platforms]);

    const options = useMemo(() => ({
        ...ChartJsDefaultOptions,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
    }), []);

    const handleChangePeriod = (value: number): void => {
        setPeriod(value);
    };

    const handleClickPlatform = (
        value: boolean,
        type: number,
    ): void => {
        if (value) {
            setDisplayedPlatforms((prev) => prev.concat(type));
        } else {
            setDisplayedPlatforms((prev) => prev.filter((item) => item !== type));
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Select
                    value={period}
                    onChange={handleChangePeriod}
                    className={styles.select}
                >
                    {periods.map((option) => (
                        <Select.Option
                            key={Math.random() * Date.now()}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>

                <div className={styles.platforms}>
                    {platforms.map((item, index) => (
                        <div
                            key={item.type}
                            className={styles.checkbox}
                        >
                            <Checkbox
                                checked={Boolean(
                                    displayedPlatforms.filter(
                                        (displayed) => displayed === item.type,
                                    )[0],
                                )}
                                onChange={(e) => handleClickPlatform(
                                    e.target.checked,
                                    item.type,
                                )}
                            />
                            <div
                                className={styles.checkboxLabel}
                                style={{ color: ChartColors[index] }}
                            >
                                {item.label}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.chartContainer}>
                <div className={styles.chart}>
                    <Line options={options as any} data={dataSet as any} />
                </div>

                {isLoading && (
                    <div className={styles.chartLoader}>
                        <Spin size="large" />
                    </div>
                )}
            </div>
        </div>
    );
};
export default CommentsAmountChart;
