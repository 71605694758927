import { FormikProps, useFormik } from 'formik';
import { authorsRtkQService } from '../../../services/Authors';
import {
    IAuthor,
    IEditAuthorQueryParams,
} from '../../../services/Authors/types';
import AuthorEditModalComponent from './component';
import validationScheme from './validationSchema';

interface IProps {
    author: IAuthor | null;
    clearEditableAuthor: () => void;
}

const AuthorEditModal: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { author, clearEditableAuthor } = props;

    const [editAuthor, { isLoading, isSuccess, isError }] = authorsRtkQService.usePutEditAuthorMutation();

    const formik: FormikProps<IEditAuthorQueryParams> = useFormik<IEditAuthorQueryParams>({
        initialValues: {
            title: author.title,
            isBlogger: author.isBlogger,
        } as IEditAuthorQueryParams,
        validationSchema: validationScheme,
        validateOnChange: false,

        onSubmit: async (values: IEditAuthorQueryParams) => {
            editAuthor({
                authorId: author.id,
                params: values,
            });
        },
    });

    const handleClickClose = (): void => {
        clearEditableAuthor();
    };

    return (
        <AuthorEditModalComponent
            formik={formik}
            handleClickClose={handleClickClose}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
        />
    );
};
export default AuthorEditModal;
