import { Select, Spin } from 'antd';
import {
    CategoryScale, Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import SourceService from '../../../services/Source/SourceService';
import { GranularityEnum, ISimpleEntity } from '../../../store/types';
import ChartColors from '../../../utils/ChartJs/ChartColors';
import ChartJsDefaultOptions from '../../../utils/ChartJs/ChartJsDefaultOptions';
import styles from './styles.module.scss';

ChartJS.register(
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
);

const periods: ISimpleEntity[] = [
    { label: 'За 7 дней', value: 7 },
    { label: 'За 14 дней', value: 14 },
    { label: 'За месяц', value: 30 },
];

const SourceGrowthChart: React.FC = (): React.ReactElement => {
    const [data, setData] = useState<{ x: any; y: number }[][]>([]);
    const [period, setPeriod] = useState<number>(7);
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response = await SourceService.getByDate({
                period_num: period,
                granularity: GranularityEnum.day,
            });

            const arr = response.data.reduce(
                (acc, item) => {
                    const newAcc = acc;
                    newAcc[0].push({
                        x: item.dt,
                        y: item.auto_discover_true,
                    });
                    newAcc[1].push({
                        x: item.dt,
                        y: item.auto_discover_false,
                    });
                    return newAcc;
                },
                [[], []],
            );
            setData(arr);
            setLoading(false);
        };

        fetchData();
    }, [period]);

    const dataSet = {
        datasets: [
            {
                type: 'line',
                data: data[0],
                borderWidth: 1,
                borderColor: ChartColors[0],
                backgroundColor: ChartColors[0],
                label: 'Авто обнаружение: да',
            },
            {
                type: 'line',
                data: data[1],
                borderWidth: 1,
                borderColor: ChartColors[1],
                backgroundColor: ChartColors[1],
                label: 'Авто обнаружение: нет',
            },
        ],
    };

    const options = useMemo(() => ({
        ...ChartJsDefaultOptions,
        plugins: {
            legend: {
                // display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
    }), []);

    const handleChangePeriod = (value: number): void => {
        setPeriod(value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Select
                    value={period}
                    onChange={handleChangePeriod}
                    className={styles.select}
                >
                    {periods.map((option) => (
                        <Select.Option
                            key={Math.random() * Date.now()}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className={styles.chartContainer}>
                <div className={styles.chart}>
                    <Line options={options as any} data={dataSet as any} />
                </div>

                {isLoading && (
                    <div className={styles.chartLoader}>
                        <Spin size="large" />
                    </div>
                )}
            </div>
        </div>
    );
};
export default SourceGrowthChart;
