import { useMemo, useState } from 'react';
import { parserManagerRtkQService } from '../../../services/ParserManager';
import LastTasksModalComponent from './component';

interface IProps {
    sourceId: number;
    taskTypeId: number;
}

const LastTasksModal: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { sourceId, taskTypeId } = props;
    const [getTasks, { data: tasks, isFetching: isTasksFetching, isError: isTasksError }] = parserManagerRtkQService.useLazyGetTasksQuery();
    const [isOpen, setOpen] = useState<boolean>(false);
    const { data: statuses, isFetching: isStatusesFetching, isError: isStatusesError } = parserManagerRtkQService.useGetStatusesQuery();
    const { data: taskTypes, isFetching: isTaskTypesFetching, isError: isTaskTypesError } = parserManagerRtkQService.useGetTaskTypesQuery();

    const handleClickGetTasks = (): void => {
        setOpen(true);
        getTasks({
            sourceId,
            taskTypeId,
            args: {
                page: 1,
                limit: 10,
            },
        });
    };

    const handleClickClose = (): void => {
        setOpen(false);
    };

    const isFetching = useMemo(
        () => isTasksFetching || isStatusesFetching || isTaskTypesFetching,
        [isTasksFetching, isStatusesFetching, isTaskTypesFetching],
    );

    const isError = useMemo(
        () => isTasksError || isStatusesError || isTaskTypesError,
        [isTasksError, isStatusesError, isTaskTypesError],
    );

    const getStatusDescription = (id: number): string => {
        const status = statuses.filter((item) => item.id === id);
        return status[0] ? status[0].name : 'Не определено';
    };

    const taskTypeDescription = useMemo(() => {
        if (!taskTypes) return '';
        const task = taskTypes.filter((item) => item.id === taskTypeId);
        return task[0] ? task[0].description : 'Не определено';
    }, [taskTypes, taskTypeId]);

    return (
        <LastTasksModalComponent
            isOpen={isOpen}
            handleClickClose={handleClickClose}
            handleClickGetTasks={handleClickGetTasks}
            tasks={tasks?.data}
            sourceId={sourceId}
            isFetching={isFetching}
            getStatusDescription={getStatusDescription}
            taskTypeDescription={taskTypeDescription}
            isError={isError}
        />
    );
};
export default LastTasksModal;
