const ChartJsDefaultOptions = {
    spanGaps: 1000 * 60 * 60 * 24 * 1,
    responsive: true,
    maintainAspectRatio: false,
    beginAtZero: true,
    stacked: false,
    elements: {
        line: {
            tension: 0.37,
        },
        point: {
            borderColor: 'white',
        },
    },
    scales: {
        x: {
            ticks: {
                padding: 10,
            },
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    day: 'DD.MM',
                    week: 'DD.MM',
                },
            },
        },
        y: {
            ticks: {
                padding: 10,
                maxTicksLimit: 7,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
};

export default ChartJsDefaultOptions;
