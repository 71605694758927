import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import AuthPage from './pages/auth';
import AuthorsPage from './pages/authors';
import ChartsPage from './pages/charts';
import MainPage from './pages/main';
import SourcesPage from './pages/sources';
import NonAuthLayout from './routes/non-auth-layout/NonAuthLayout';
import Path from './routes/Path';
import ProtectedLayout from './routes/protected-layout/ProtectedLayout';
import { store } from './store/store';
import ParserManagerSourcesPage from './pages/ParserManager';
import ParserManagerTaskGroupPage from './pages/ParserManagerTaskGroup';
import ParserManagerAccounts from './pages/ParserManagerAccounts';
import ParserManagerProxy from './pages/ParserManagerProxy';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route element={<NonAuthLayout />}>
                    <Route path="*" element={<div>404</div>} />
                    <Route path={Path.Auth} element={<AuthPage />} />
                </Route>

                <Route element={<ProtectedLayout />}>
                    <Route path={Path.App} element={<MainPage />} />
                    <Route path={Path.Sources} element={<SourcesPage />} />
                    <Route path={Path.Charts} element={<ChartsPage />} />
                    <Route path={Path.Authors} element={<AuthorsPage />} />
                    <Route path={Path.ParserManagerSources} element={<ParserManagerSourcesPage />} />
                    <Route path={Path.ParserManagerTaskGroup} element={<ParserManagerTaskGroupPage />} />
                    <Route path={Path.ParserManagerAccounts} element={<ParserManagerAccounts />} />
                    <Route path={Path.ParserManagerProxy} element={<ParserManagerProxy />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </Provider>,
);
