/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Radio, RadioChangeEvent, Select, Space, Typography,
} from 'antd';
import {
    IGetAccountListOrderColumns, IParserManagerStatuses, IParserManagerAccountModel, IQueryParamsGetAccountList,
} from '../../services/ParserManager/types';
import { parserManagerRtkQService } from '../../services/ParserManager';
import ParserManagerAccountsComponent from './component';
import { ITableColumn } from '../../components/TempTable/types';
import styles from './styles.module.scss';
import { sourceManagerRtkQService } from '../../services/Source';
import { SortOrder } from '../../services/types';

const ParserManagerAccounts: React.FC = (): React.ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState<number>(() => Number(searchParams.get('page')));
    const navigate = useNavigate();
    const location = useLocation();
    const [sort, setSort] = useState<{
        column: IGetAccountListOrderColumns;
        order: SortOrder;
    } | null>(null);

    const [getAccounts, { data: accounts, isFetching: accountsIsFetching, isError: accountsIsError }] = parserManagerRtkQService.useLazyGetAccountListQuery();
    const { data: sourceTypes, isFetching: isSourceTypesFetching, isError: sourceTypesIsError } = sourceManagerRtkQService.useGetSourceTypesQuery();
    const [filterStatus, setFilterStatus] = useState<IParserManagerStatuses | null>(null);
    const [filterSourceType, setFilterSourceType] = useState<number | null>(null);

    useEffect(() => {
        const pageValue = Number(searchParams.get('page'));
        if (pageValue === 0) {
            navigate(`${location.pathname}?page=1`);
        }
        setPage(pageValue);
    }, [searchParams, navigate, location]);

    const args = useMemo(() => {
        const params: IQueryParamsGetAccountList = {};

        if (sort) {
            params.order_by = sort.column;
            params.order_direction = sort.order;
        }
        if (filterStatus) params.status = filterStatus;
        if (filterSourceType) params.source_type_id = filterSourceType;
        // if (search) params.search = search;
        // if (filter) params.enabled = filter.enabled;

        return params;
    }, [sort, filterStatus, filterSourceType]);

    useEffect(() => {
        if (page) {
            getAccounts({
                ...args,
                page: Number(page),
            });
        }
    }, [page, getAccounts, args]);

    const onSortTable = (
        column: ITableColumn<IParserManagerAccountModel>,
        order: SortOrder,
    ): void => {
        setSort({
            column: IGetAccountListOrderColumns[column.dataIndex],
            order,
        });
    };

    const handleChangeStatus = (e: RadioChangeEvent): void => {
        setFilterStatus(e.target.value);
    };

    const handleChangeSourceType = (e: RadioChangeEvent): void => {
        setFilterSourceType(e.target.value);
    };

    const columns: ITableColumn<IParserManagerAccountModel>[] = useMemo(() => [
        {
            id: 1,
            dataIndex: 'id',
            title: 'ID',
            width: 100,
            sortable: true,
        },
        {
            id: 2,
            dataIndex: 'status',
            title: 'Статус',
            width: 125,
            renderFilter: () => (
                <div className={styles.filterStatus}>
                    <Radio.Group onChange={handleChangeStatus} value={filterStatus}>
                        <Space direction="vertical">
                            <Radio value={null}>Не учитывать</Radio>
                            <Radio value={IParserManagerStatuses.available}>available</Radio>
                            <Radio value={IParserManagerStatuses.temporaryBan}>temporary ban</Radio>
                            <Radio value={IParserManagerStatuses.permanentBan}>permanent ban</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            ),
        },
        {
            id: 3,
            dataIndex: 'bannedUntil',
            title: 'Бан',
            sortable: true,
            render: (dateString: string) => (
                <span>{dateString ? new Date(dateString).toLocaleString() : 'Нет информации'}</span>
            ),
        },
        {
            id: 4,
            dataIndex: 'banInfo',
            title: 'Инф. Бан',
            maxLengthForTooltipe: 20,
        },
        {
            id: 5,
            dataIndex: 'createdAt',
            title: 'Создано',
            sortable: true,
            render: (dateString: string) => (
                <span>{dateString ? new Date(dateString).toLocaleString() : 'Нет информации'}</span>
            ),
        },
        {
            id: 6,
            dataIndex: 'lastUsed',
            title: 'Послед. использование',
            sortable: true,
            render: (dateString: string) => (
                <span>{dateString ? new Date(dateString).toLocaleString() : 'Нет информации'}</span>
            ),
        },
        {
            id: 7,
            dataIndex: 'failedCounter',
            title: 'Неудач. попытки',
            sortable: true,
        },
        {
            id: 8,
            dataIndex: 'sourceTypeId',
            title: 'Тип источника',
            render: (value: number) => (
                <span>{sourceTypes.filter((item) => item.id === value)[0].name}</span>
            ),
            renderFilter: () => (
                <Radio.Group onChange={handleChangeSourceType} value={filterSourceType}>
                    <Space direction="vertical">
                        <Radio value={null}>Не учитывать</Radio>
                        {sourceTypes && sourceTypes.map((item) => <Radio key={item.id} value={item.id}>{item.name}</Radio>)}
                    </Space>
                </Radio.Group>
            ),
        },
        {
            id: 9,
            dataIndex: 'credentials',
            title: 'Данные',
            render: (credentials) => (
                <div className={styles.credentials}>
                    {Object.keys(credentials).map((key) => <span key={key}>{`${key}: ${credentials[key]}`}</span>)}
                </div>
            ),
        },
    ], [sourceTypes, filterStatus, filterSourceType]);

    const onChangePage = (nextPage: number): void => {
        setSearchParams({ page: String(nextPage) });
    };

    const isFetching = useMemo(
        () => accountsIsFetching || isSourceTypesFetching,
        [accountsIsFetching, isSourceTypesFetching],
    );

    const isError = useMemo(
        () => accountsIsError || sourceTypesIsError,
        [accountsIsError, sourceTypesIsError],
    );

    return (
        <ParserManagerAccountsComponent
            columns={columns}
            isFetching={isFetching}
            accounts={accounts?.data}
            total={accounts?.total}
            page={page}
            onChangePage={onChangePage}
            onSortTable={onSortTable}
            isError={isError}
        />
    );
};
export default ParserManagerAccounts;
