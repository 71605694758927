import {
    ApartmentOutlined, AreaChartOutlined, RadarChartOutlined, UserOutlined,
} from '@ant-design/icons';
import Path from '../../routes/Path';
import NarrowMenuItem from './NarrowMenuItem';
import styles from './styles.module.scss';
import { IMenuItem } from './types';

const menuData: IMenuItem[] = [
    {
        id: '2',
        href: `${Path.Sources}?page=1`,
        icon: <ApartmentOutlined />,
        label: 'Источники',
    },
    {
        id: '3',
        href: Path.Charts,
        icon: <AreaChartOutlined />,
        label: 'Графики',
    },
    {
        id: '4',
        href: Path.Authors,
        icon: <UserOutlined />,
        label: 'Авторы',
    },
    {
        id: '4',
        href: Path.ParserManagerSources,
        icon: <RadarChartOutlined />,
        label: 'Парсер менеджер. Источники',
    },
    {
        id: '5',
        href: Path.ParserManagerTaskGroup,
        icon: <RadarChartOutlined />,
        label: 'Парсер менеджер. Группы задач',
    },
    {
        id: '6',
        href: Path.ParserManagerAccounts,
        icon: <RadarChartOutlined />,
        label: 'Парсер менеджер. Список аккаунтов.',
    },
    {
        id: '7',
        href: Path.ParserManagerProxy,
        icon: <RadarChartOutlined />,
        label: 'Парсер менеджер. Прокси',
    },
];

interface Props {
    menuExpanded: boolean;
}

const NarrowMenu: React.FC<Props> = (props: Props): React.ReactElement => {
    const { menuExpanded } = props;

    return (
        <ul className={styles.menu}>
            {menuData.map((item) => (
                <NarrowMenuItem key={item.href} item={item} menuExpanded={menuExpanded} />
            ))}
        </ul>
    );
};
export default NarrowMenu;
