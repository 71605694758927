import * as Yup from 'yup';

const validationScheme = Yup.object({
    title: Yup.string().required(),
    is_blogger: Yup.boolean().required(),
    is_contracted: Yup.boolean().required(),
    is_reliable: Yup.boolean().required(),
});

export default validationScheme;
