import { AxiosResponse } from 'axios';
import { sha512 } from 'js-sha512';
import $api from '../../api';
import { ApiRoutes } from '../../routes/ApiRoutes';
import { ILoginArgs } from './types';

export default class AuthService {
    static async login(
        args: ILoginArgs,
    ): Promise<AxiosResponse<any>> {
        return $api.post(ApiRoutes.auth.login, {
            email: args.email,
            password: sha512(args.password),
        });
    }
}
