import { Alert } from 'antd';
import TempTable from '../../components/TempTable';
import { ITableColumn } from '../../components/TempTable/types';
import { IParserManagerProxyModel } from '../../services/ParserManager/types';
import { SortOrder } from '../../services/types';
import styles from './styles.module.scss';

interface IProps {
    columns: ITableColumn<IParserManagerProxyModel>[]
    isFetching: boolean
    proxyData: IParserManagerProxyModel[]
    total: number
    page: number
    onChangePage: (page: number) => void
    onSortTable: (column: ITableColumn<IParserManagerProxyModel>, order: SortOrder) => void
    isError: boolean
}

const ParserManagerProxyComponent: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        columns, isFetching, proxyData, total, page, onChangePage, isError,
        onSortTable,
    } = props;
    return (
        <div className={styles.wrapper}>
            <TempTable
                rows={proxyData ?? []}
                columns={columns}
                isLoading={isFetching}
                pagination={{
                    total,
                    currentPage: page,
                    onChange: onChangePage,
                    limit: 10,
                }}
                onSort={onSortTable}
            />
            {!isFetching && proxyData?.length < 1 && !isError && (
                <Alert
                    type="info"
                    message="Нет записей"
                />
            )}
            {isError && !isFetching && (
                <Alert
                    type="error"
                    message="Произошла ошибка при получении данных с сервера."
                />
            )}
        </div>
    );
};
export default ParserManagerProxyComponent;
