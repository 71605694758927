/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Pagination as AntPagination, PaginationProps } from 'antd';
import React, { useState } from 'react';
import classNames from 'classnames';
import { ITablePagination } from '../types';
import styles from './styles.module.scss';
import './ant.styles.scss';

interface IProps extends ITablePagination {}

const Pagination: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        currentPage, total, onChange, limit,
    } = props;
    const [newCurrentPage, setNewCurrentPage] = useState('');
    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <div className={classNames(styles.button, styles.prev)}>
                    <svg width={11} height={17} viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16L10 8.5L1 1" stroke="#787E93" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            );
        }
        if (type === 'next') {
            return (
                <div className={styles.button}>
                    <svg width={11} height={17} viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16L10 8.5L1 1" stroke="#787E93" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            );
        }
        return originalElement;
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && newCurrentPage && newCurrentPage !== '0') {
            onChange(+newCurrentPage);
        }
    };
    return (
        <>
            <AntPagination
                className={styles.pagination}
                showSizeChanger={false}
                onChange={onChange}
                current={currentPage}
                total={total}
                itemRender={itemRender}
                pageSize={limit}
            />
            <div className={styles.containerCotroler} onKeyDown={handleKeyDown}>
                <span>Перейти на страницу</span>
                <input type="text" value={newCurrentPage} onChange={(e) => setNewCurrentPage(e.target.value.replace(/[^0-9]+/g, ''))} />
                <div className={styles.btn} onClick={() => newCurrentPage && newCurrentPage !== '0' && onChange(+newCurrentPage)}>
                    Перейти
                </div>
            </div>
        </>
    );
};
export default Pagination;
